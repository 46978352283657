export const loadFonts = {
  loadGoogleFont
}

async function loadGoogleFont (payload) {
  try {
    const { url, fontFamily, type } = JSON.parse(payload)

    if (!url || !fontFamily || !url.startsWith('https://fonts.googleapis.com')) throw new Error('Font invalid')

    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = url

    document.head.appendChild(link)

    let font = fontFamily

    if (type) { font = `${fontFamily}, ${type}` }

    document.documentElement.style.setProperty('--binds_font_family', font)
  } catch (error) {
    console.error('Erro ao carregar a fonte:', error)
  }
}
