<template>
  <i class="binds-svg-loader" v-html="html"></i>
</template>

<script>
let bindsSVGStore = {}

export default {
  name: 'BindsSVGLoader',
  props: {
    bindsSrc: {
      type: String,
      required: true
    }
  },
  data: () => ({
    html: null,
    error: null
  }),
  watch: {
    bindsSrc () {
      this.html = null
      this.loadSVG()
    }
  },
  methods: {
    isSVG (mimetype) {
      if (typeof mimetype !== 'string') return false
      return mimetype.indexOf('svg') >= 0
    },
    setHtml (value) {
      bindsSVGStore[this.bindsSrc].then((html) => {
        this.html = html

        return this.$nextTick()
      }).then(() => this.$emit('binds-loaded'))
    },
    unexpectedError (reject) {
      this.error = `Something bad happened trying to fetch ${this.bindsSrc}.`
      reject(this.error)
    },
    loadSVG () {
      if (!bindsSVGStore.hasOwnProperty(this.bindsSrc)) {
        bindsSVGStore[this.bindsSrc] = new Promise((resolve, reject) => {
          const request = new window.XMLHttpRequest()

          request.open('GET', this.bindsSrc, true)

          request.onload = () => {
            const mimetype = request.getResponseHeader('content-type')

            if (request.status === 200) {
              if (this.isSVG(mimetype)) {
                resolve(request.response)
                this.setHtml()
              } else {
                this.error = `The file ${this.bindsSrc} is not a valid SVG.`
                reject(this.error)
              }
            } else if (request.status >= 400 && request.status < 500) {
              this.error = `The file ${this.bindsSrc} do not exists.`
              reject(this.error)
            } else {
              this.unexpectedError(reject)
            }
          }

          request.onerror = () => this.unexpectedError(reject)
          request.onabort = () => this.unexpectedError(reject)
          request.send()
        })
      } else {
        this.setHtml()
      }
    }
  },
  mounted () {
    this.loadSVG()
  }
}
</script>

<style lang="scss">
  .binds-svg-loader {
    display: block;

    svg {
      width: 100%;
    }
  }
</style>
