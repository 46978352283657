const state = emptyQuestion()

function emptyQuestion () {
  return {
    question: []
  }
}
const mutations = {
  setQuestion (state, payload) {
    state.question = payload
  },
  clearQuestionState (state, params) {
    state = emptyQuestion()
  }
}

const getters = {
  getQuestion: state => state.question

}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
