import { render, staticRenderFns } from "./BindsBadgeStandalone.vue?vue&type=template&id=53d498cb&"
import script from "./BindsBadgeStandalone.vue?vue&type=script&lang=js&"
export * from "./BindsBadgeStandalone.vue?vue&type=script&lang=js&"
import style0 from "./BindsBadgeStandalone.vue?vue&type=style&index=0&id=53d498cb&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports