<template>
  <div>
    <!-- UI ynm -->
    <div class="row center-lg center-md op-ynd-wid binds-animated-faster zoomIn" v-if="data.question.ui === 'ynm'">
      <app-button :widget="true" :binds-ripple="false" style="background: #57ad26; box-shadow: 0 5px 15px 0 #d7d7d7 !important;" class="binds-raised bind-space-between-buttons" variation="primary" v-on:click.native="postResponse(ynm.options[0].rating)">{{ $t('answers.yes') }}</app-button>
      <app-button :widget="true" :binds-ripple="false" style="background: #ea484d; box-shadow: 0 5px 15px 0 #d7d7d7 !important;" class="binds-raised bind-space-between-buttons" variation="secondary" v-on:click.native="postResponse(ynm.options[2].rating)">{{ $t('answers.no') }}</app-button>
      <app-button :widget="true" :binds-ripple="false" style="background: #f8c43d !important; color: #ffffff !important; box-shadow: 0 5px 15px 0 #d7d7d7 !important;" class="binds-raised bind-space-between-buttons" outline v-on:click.native="postResponse(ynm.options[1].rating)">{{ $t('answers.maybe') }}</app-button>
    </div>
    <!-- UI 10num -->
    <div v-if="data.question.ui === '10num'">
      <div class="binds-layout align-buttons">
        <div class="content-10num">
          <app-form-rate
            v-model="inputValue"
            :min-label="data.question.startLabel"
            :max-label="data.question.endLabel"
            textColor="#fff"
            :labelColor="data.response_font_color || data.texts"
            :bgColor="data.buttons"
            :items="options"
            :isGradient="data.question.gradient"
            item-value="value"
            item-text="label"
            @rate-value="postResponse"
          />
        </div>
      </div>
    </div>
    <div class="submit-options-matrix" style="text-align: center;" v-if="buttonEnabled">
      <app-button
      :binds-ripple="false"
      :widget="true"
      class="binds-raised binds-primary"
      style="box-shadow: none;background: none;"
      :style="{'color': data.buttons}"
      v-on:click="postResponse('')">{{data.question.nextLabel}}</app-button>
    </div>
  </div>
</template>

<script>
import { widgetService } from '@/_services'
import { gradientMaker, translator } from '@/_helpers'

export default {
  props: ['data'],
  data () {
    return {
      inputValue: '',
      buttonEnabled: true,
      ynm: {
        options: [
          { rating: 95, label: this.$i18n.t('answers.yes') },
          { rating: 75, label: this.$i18n.t('answers.maybe') },
          { rating: 5, label: this.$i18n.t('answers.no') }
        ]
      },
      options: [
        { value: 0, label: '0' },
        { value: 10, label: '1' },
        { value: 20, label: '2' },
        { value: 30, label: '3' },
        { value: 40, label: '4' },
        { value: 50, label: '5' },
        { value: 60, label: '6' },
        { value: 70, label: '7' },
        { value: 80, label: '8' },
        { value: 90, label: '9' },
        { value: 100, label: '10' }
      ]
    }
  },
  methods: {
    async postResponse (rating) {
      if (this.data.is_to_post) {
        this.$root.$emit('setLoading', true)
        let data = ''
        rating !== '' ? data = { rating, 'sending': this.data.sending } : data = { rating, skip: true, 'sending': this.data.sending }
        const questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, data)
        this.$root.$emit('questionUpdated', questionUpdated, rating)
      }
    }
  },
  created () {
    this.$i18n.locale = translator.getCurrentLocale(this.data.question.language)
    gradientMaker.checkGradientAndReturnTheColors(this.data.question, this.options)
    if (this.data.question.required === true) {
      this.buttonEnabled = false
    }
  },
  mounted () {
    this.$root.$emit('setLoading', false)
  }
}
</script>

<style lang="scss">
  .elevation-demo {
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
  }

  .binds-content {
    width: 100px;
    height: 100px;
    margin: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .yes {
    background-color: #03B771 !important;
  }
  .maybe {
    background-color: rgb(155, 153, 153) !important;
  }
  .no {
    background-color: #FC6C5D !important;
  }
  .mt30 {
    margin-top: 30px;
  }

  .content-buttons-num .binds-button{
    min-width: 10px;
    margin: 0;
  }
</style>
