<template>
  <div class="binds-text-component">
    <div class="responsive-widget" >
      <div class="binds-animated-faster zoomIn">
        <form  v-on:submit="save($event, entry)" v-if="data.question.ui === 'referral'" id="binds-mobile-referral">
          <div>
            <app-form-input
              v-if="data.question.referral.fields.name.active"
              v-model="entry.name"
              colorText="#080808"
              :required="data.question.referral.fields.name.required"
              :placeholder="data.question.referral.fields.name.placeholder"
              border-bottom
              secondary
              border-solid
            />
          </div>
          <div>
            <app-form-input
              v-if="data.question.referral.fields.email.active"
              v-model="entry.email"
              :required="data.question.referral.fields.email.required"
              colorText="#080808"
              :placeholder="data.question.referral.fields.email.placeholder"
              v-on:keyup.native="clean()"
              border-bottom
              secondary
              border-solid
            />
            <p class="alert-message" v-if="alertEmail">{{alertEmail}}</p>
          </div>
          <div>
            <app-form-input
              v-if="data.question.referral.fields.phone.active"
              :required="data.question.referral.fields.phone.required"
              v-model="entry.phone"
              colorText="#080808"
              :placeholder="data.question.referral.fields.phone.placeholder"
              @keyup="validatePhone($event)"
              border-bottom
              secondary
              border-solid
          />
          </div>
          <div class="submit-options-matrix" style="text-align: center;">
            <app-button
                :widget="true"
                :binds-ripple="false"
                class="binds-raised binds-primary"
                style="box-shadow: none;background: none;"
                :style="{'color': data.buttons}"
                @click.prevent="save($event, null)"
              >{{data.question.nextLabel}}
            </app-button>
          </div>
        </form>
        <form  v-if="data.question.ui === 'referralDynamic'" v-on:submit="save($event)" id="binds-mobile-referralDynamic">
          <div v-for="(field, index) in data.question.referral.dynamicFields" v-bind:key="index">
            <app-form-input
              v-if="field.active"
              v-model="entry[index]"
              colorText="#080808"
              :placeholder="field.placeholder"
              :required="field.required"
              border-bottom
              secondary
              border-solid
            />
          </div>
          <div class="submit-options-matrix" style="text-align: center;">
            <app-button
                :binds-ripple="false"
                :widget="true"
                @click.prevent="save($event, null)"
                class="binds-raised binds-primary"
                style="box-shadow: none;background: none;"
                :style="{'color': data.buttons}"
              >{{data.question.nextLabel}}</app-button>
          </div>
        </form>
        <form v-if="!~['referralDynamic', 'referral'].indexOf(data.question.ui)" v-on:submit="save($event, entrySingle)">
          <div>
            <app-form-input
              v-model="entrySingle"
              colorText="#080808"
              placeholder="Nome"
              border-bottom
              secondary
              border-solid
            />
          </div>
          <div class="submit-options-matrix" style="text-align: center;">
            <app-button
                :binds-ripple="false"
                :widget="true"
                class="binds-raised binds-primary"
                style="box-shadow: none;background: none;"
                :style="{'color': data.buttons}"
              >{{data.question.nextLabel}}</app-button>
          </div>
        </form>
      </div>
    </div>

  </div>
</template>

<script>

import { widgetService } from '@/_services'

export default {
  props: ['data'],
  data () {
    return {
      entry: {},
      entrySingle: '',
      alertEmail: false
    }
  },
  methods: {
    validatePhone (evt) {
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        this.entry.phone = this.entry.phone.replace(/[^\d\s+|\-,]/g, '')
        evt.preventDefault()
      } else {
        return true
      }
    },
    save (event) {
      let refError = false
      let objectToPost = ''
      if (this.data.question.ui === 'referralDynamic') {
        objectToPost = { 'referralEntries': this.entry || [] }
      }
      // email validate
      if (this.entry.email && this.entry.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) === null) {
        this.alert(this.$i18n.t('answers.referral.alert_email'))
        refError = true
      }
      // check if entry is empty to add skip question
      Object.keys(this.entry).length === 0 ? objectToPost = { skip: true } : objectToPost = { 'referralEntries': this.entry ? [ this.entry ] : [] }
      objectToPost.sending = this.data.sending

      if (this.data.is_to_post && !refError) {
        this.$root.$emit('setLoading', true)
        this.submitEvent(objectToPost)
      }
      event.preventDefault()
    },
    async submitEvent (data) {
      let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, data)
      this.$root.$emit('questionUpdated', questionUpdated)
      this.$root.$emit('setLoading', false)
      this.entry = {}
    },
    alert (message) {
      this.alertEmail = message
    },
    clean () {
      if (!this.entry.email) {
        this.alertEmail = false
        delete this.entry.email
      }
    }
  }
}
</script>

<style lang="scss">
@media screen and (max-width: 430px) {
  .binds-text-component .text-field__input {
    input.user-click {
      width: 18rem !important;
    }
  }
  #binds-mobile-referral {
    .binds-text-component .text-field__input {
      margin-bottom: 20px;
      input.user-click {
        width: 18rem !important;
      }
    }
    .text-field__input input.user-click {
      width: 18rem !important;
    }
  }
}
@media screen and (max-width: 360px) {
  .binds-text-component .text-field__input {
    input.user-click {
      width: 16rem !important;
    }
  }
  #binds-mobile-referral {
    .binds-text-component .text-field__input {
      margin-bottom: 20px;
      input.user-click {
        width: 16rem !important;
      }
    }
    .text-field__input input.user-click {
      width: 16rem !important;
    }
  }
}
.binds-text-component {
  padding: 10px;
  margin-top: 15px;

  .text-field__input input {
    font-size: 17px !important;

    &.user-click {
      width: 22rem;
    }
  }
}

.submit-options-matrix {
  padding-top: 1rem;
}

.alert-message {
  color: #D91212ed;
  display: block;
  margin-top: 10px;
  margin-left: 5px;
}
</style>
