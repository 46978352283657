<template>
  <div>
    <div class="binds-layout-item binds-layout binds-gutter" id="img-container" v-if="data.endMessage.logo">
        <div class="binds-layout-item binds-size-50 binds-alignment-top-center binds-center-div" v-if="data.endMessage.logo.link">
          <img :src="imageMessage"  alt="End Message">
        </div>
    </div>

    <div class="binds-layout-item binds-layout binds-gutter" style="margin: 15px 0;">
        <div class="binds-layout-item binds-size-50 binds-alignment-top-center binds-center-div">
            <h6 class="binds-heading" :style="{ 'color': data.texts, 'font-size': data.font_size, 'font-family': data.font_family  }" v-html="addLineBreakToString(data.endMessage.message)"></h6>
        </div>
    </div>
    <div class="binds-layout-item binds-layout binds-gutter">
        <div class="binds-layout-item binds-size-50 binds-alignment-top-center binds-center-div"
          v-if="data.endMessage.details">
          <p class="binds-layout-details binds-heading" v-if="data.endMessage.details.link" :style="{'color': data.texts}">
            {{data.endMessage.details.value}}
            <a :href="data.endMessage.details.link.value" target="_blank" >
              <p class="binds-heading" style="text-decoration: underline;" :style="{'color': data.texts}">{{data.endMessage.details.link.label}}</p>
            </a>
          </p>
        </div>
    </div>
  </div>
</template>

<script>
import { widgetService } from '@/_services'
export default {
  props: ['data'],
  async mounted () {
    this.$root.$emit('setLoading', false)
    const done = await widgetService.done(this.data)
    if (done && done.status === 200 && this.data.hidde_after_answer) {
      setTimeout(() => {
        this.$root.$emit('hiddeAfterAnswer')
      }, 2000)
    }
  },
  computed: {
    imageMessage () {
      let img
      if (!this.data.endMessage.logo.url) {
        img = this.data.endMessage.logo.link
      } else {
        img = this.data.endMessage.logo.url
      }
      return img
    }
  },
  methods: {
    addLineBreakToString (text) {
      return text.replace(/\n/gi, '<br />')
    }
  }
}
</script>
<style lang="scss">
  .binds-gutter {
    .binds-center-div {
      padding: 0 !important;
      min-width: 100% !important;
      display: flex;
      justify-content: center;
      img {
        max-width: 60% !important;
      }
    }
  }
  .binds-layout-item {
    text-align: center;
    &#img-container {
      margin-top: 50px;
    }
    p.binds-heading {
      padding: 10px;
      line-height: 1;
      font-size: 1rem;
      margin: 0;
    }
    p.binds-layout-details {
      margin: 5px 0;
      text-align: center;
      a p {
        margin: 10px 0;
      }
    }
  }
</style>
