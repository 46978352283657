<template>
  <textarea
    class="binds-textarea"
    :style="textareaStyles"
    v-model="model"
    v-bind="attributes"
    v-on="listeners"
    @focus="onFocus"
    @blur="onBlur"
  ></textarea>
</template>

<script>
import BindsComponent from '../../../core/BindsComponent'
import BindsUuid from '../../../core/utils/BindsUuid'
import BindsFieldMixin from '../BindsFieldMixin'

function calculateContentHeight (el, lineHeight) {
  const origHeight = el.style.height
  const height = el.offsetHeight
  const scrollHeight = el.scrollHeight

  el.style.overflow = 'hidden'

  if (height >= scrollHeight) {
    el.style.height = height + lineHeight + 'px'

    if (scrollHeight < el.scrollHeight) {
      el.style.height = origHeight

      return height
    }
  }

  return scrollHeight
}

export default new BindsComponent({
  name: 'BindsTextarea',
  mixins: [BindsFieldMixin],
  inject: ['BindsField'],
  props: {
    id: {
      type: String,
      default: () => 'binds-textarea-' + BindsUuid()
    },
    bindsAutogrow: Boolean
  },
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: this.onInput
      }
    },
    textareaStyles () {
      return {
        height: this.textareaHeight
      }
    }
  },
  methods: {
    getTextAreaLineSize () {
      const style = window.getComputedStyle(this.$el)

      return parseInt(style.lineHeight, 10)
    },
    setTextAreaSize (height) {
      let newHeight = height

      if (!height) {
        const size = this.getTextAreaLineSize()

        newHeight = calculateContentHeight(this.$el, size)
      }

      this.textareaHeight = newHeight + 'px'
    },
    applyStyles () {
      if (this.bindsAutogrow) {
        this.setTextAreaSize(32)
        this.$nextTick().then(() => {
          this.setTextAreaSize()
          window.setTimeout(() => {
            this.$el.style.overflow = 'auto'
          }, 10)
        })
      }
    },
    setTextarea () {
      this.BindsField.textarea = true
    },
    setAutogrow () {
      this.BindsField.autogrow = this.bindsAutogrow
    },
    onInput () {
      this.setFieldValue()
    }
  },
  watch: {
    localValue () {
      this.applyStyles()
    }
  },
  created () {
    this.setTextarea()
    this.setAutogrow()
  },
  mounted () {
    this.$nextTick().then(this.applyStyles)
  },
  beforeDestroy () {
    this.setTextarea(false)
  }
})
</script>
