<template>
  <div class="binds-stepper">
    <binds-step-header v-if="BindsSteppers.isVertical" :index="id" />

    <div class="binds-stepper-content" :class="{ 'binds-active': !BindsSteppers.syncRoute && id === BindsSteppers.activeStep }">
      <slot />
    </div>
  </div>
</template>

<script>
import BindsUuid from '../../core/utils/BindsUuid'
import BindsRouterLink from '../../core/mixins/BindsRouterLink/BindsRouterLink'
import BindsStepHeader from './BindsStepHeader'

export default {
  name: 'BindsStep',
  components: {
    BindsStepHeader
  },
  mixins: [BindsRouterLink],
  props: {
    id: {
      type: String,
      default: () => 'binds-stepper-' + BindsUuid()
    },
    href: [String, Number],
    bindsLabel: String,
    bindsDescription: String,
    bindsError: String,
    bindsDone: Boolean,
    bindsEditable: {
      type: Boolean,
      default: true
    }
  },
  inject: ['BindsSteppers'],
  watch: {
    $props: {
      deep: true,
      handler () {
        this.setStepperData()
      }
    }
  },
  methods: {
    getPropValues () {
      const propNames = Object.keys(this.$options.props)
      const ignoredProps = ['id', 'bindsLabel', 'bindsDescription', 'bindsError', 'bindsEditable']
      let values = {}

      propNames.forEach(prop => {
        if (!ignoredProps.includes(prop)) {
          if (this[prop]) {
            values[prop] = this[prop]
          } else if (this.$attrs.hasOwnProperty(prop)) {
            if (prop) {
              values[prop] = this.$attrs[prop]
            } else {
              values[prop] = true
            }
          }
        }
      })

      return values
    },
    setStepperData () {
      this.$set(this.BindsSteppers.items, this.id, {
        label: this.bindsLabel,
        description: this.bindsDescription,
        error: this.bindsError,
        done: this.bindsDone,
        editable: this.bindsEditable,
        props: this.getPropValues(),
        events: this.$listeners
      })
    },
    setupWatchers () {
      const getProp = prop => {
        if (this.BindsSteppers.items[this.id]) {
          return this.BindsSteppers.items[this.id][prop]
        }
      }

      this.$watch(
        () => getProp('error'),
        () => this.$emit('update:bindsError', getProp('error'))
      )

      this.$watch(
        () => getProp('done'),
        () => this.$emit('update:bindsDone', getProp('done'))
      )
    }
  },
  created () {
    this.setStepperData()
    this.setupWatchers()
  },
  beforeDestroy () {
    this.$delete(this.BindsSteppers.items, this.id)
  },
  render (createElement) {
    let stepperAttrs = {
      staticClass: 'binds-stepper',
      attrs: {
        ...this.$attrs,
        id: this.id
      },
      on: this.$listeners
    }

    if (this.href) {
      this.buttonProps = this.$options.props
    } else if (this.$router && this.to) {
      this.$options.props = BindsRouterLinkProps(this, this.$options.props)

      stepperAttrs.props = this.$props
    }

    return createElement('div', stepperAttrs, this.$slots.default)
  }
}
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-steppers.binds-vertical .binds-stepper-content {
    padding: 0 24px 0 60px;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transform: translate3D(0, -20px, 0);
    transition: .35s $binds-transition-stand-timing;
    transition-property: opacity, transform, height, padding-bottom;
    will-change: opacity, transform, height, padding-bottom;

    &.binds-active {
      height: auto;
      padding-bottom: 40px;
      opacity: 1;
      transform: translate3D(0, 0, 0);
    }
  }
</style>
