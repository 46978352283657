export default {
  max_length: 'Máximo de caracteres: ',
  multiple_and: 'e',
  other_required: 'Preencha o campo',
  multiple_answer_required: 'Você deve selecionar entre',
  multiple_options: 'opções',
  answers: {
    yes: 'Sim',
    no: 'Não',
    maybe: 'Talvez',
    dont_know: 'Não sei',
    excellent: 'Excelente',
    very_good: 'Muito bom',
    satifying: 'Satisfatório',
    bad: 'Ruim',
    very_bad: 'Muito ruim',
    very_satisfied: 'Muito satisfeito',
    satisfied: 'Satisfeito',
    indifferent: 'Indiferente',
    unsatisfied: 'Insatisfeito',
    very_unsatisfied: 'Muito insatisfeito',
    ces: {
      totally_disagree: 'Discordo totalmente',
      disagree: 'Discordo',
      partially_disagree: 'Discordo Parcialmente',
      neutral: 'Neutro',
      partially_agree: 'Concordo Parcialmente',
      agree: 'Concordo',
      totally_agree: 'Concordo totalmente'
    },
    nvs: {
      nvs_op1: 'Significativamente melhor',
      nvs_op2: 'Um pouco melhor',
      nvs_op3: 'Nem melhor, nem pior',
      nvs_op4: 'Um pouco pior',
      nvs_op5: 'Significativamente pior'
    },
    referral: {
      alert_email: 'E-mail inválido'
    }
  }
}
