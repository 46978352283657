import Vue from 'vue'
import Vuex from 'vuex'
import answerList from './answerList'
import questions from './questions'

Vue.use(Vuex)

const store = {
  modules: {
    answerList,
    questions
  }
}
export default new Vuex.Store(store)
