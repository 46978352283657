import i18n from '../translations/i18n'
function getOptions (question) {
  const type = question.type
  const ui = question.ui
  let options = []

  // CSAT options
  if (type === 'csat') {
    if (ui === '5emo') {
      // Muito satisfeito, satisfeito, indiferente, insatisfeito, muito insatisfeito.
      options = [
        { label: i18n.t('answers.very_satisfied'), icon: '\uE603', rating: '100' },
        { label: i18n.t('answers.satisfied'), icon: '\uE600', rating: '75' },
        { label: i18n.t('answers.indifferent'), icon: '\uE606', rating: '50' },
        { label: i18n.t('answers.unsatisfied'), icon: '\uE607', rating: '25' },
        { label: i18n.t('answers.very_unsatisfied'), icon: '\uE608', rating: '0' }
      ]
    }
    if (ui === '10num') {
      options = [...Array(11).keys()].map(item => {
        return { label: item, rating: (item * 10).toString() }
      })
    }
    if (ui === '5num') {
      options = [...Array(5).keys()].map(item => {
        return { label: item + 1, rating: (item * 25).toString() }
      })
    }
  }
  // CSAT options - END

  // NPS options
  if (type === 'nps') {
    if (ui === 'ynm') {
      options = [
        { label: i18n.t('answers.yes'), value: '95' },
        { label: i18n.t('answers.no'), value: '5' },
        { label: i18n.t('answers.maybe'), value: '75' }
      ]
    }
    if (ui === '10num') {
      options = [...Array(11).keys()].map(item => { return { label: item, rating: (item * 10).toString() } })
    }
  }
  // NPS options - END

  // NES ou CES options
  if (type === 'nes') {
    if (ui === '10num') {
      options = [...Array(5).keys()].map(item => { return { label: item + 1, rating: (item * 25).toString() } })
    }
  }
  if (type === 'ces') {
    if (ui === '1to7') {
      options = [
        { label: '1', rating: '0' },
        { label: '2', rating: '15' },
        { label: '3', rating: '30' },
        { label: '4', rating: '50' },
        { label: '5', rating: '65' },
        { label: '6', rating: '80' },
        { label: '7', rating: '100' }
      ]
    }
    if (ui === '1to7button') {
      options = [
        { label: i18n.t('answers.ces.totally_disagree'), rating: '0' },
        { label: i18n.t('answers.ces.disagree'), rating: '15' },
        { label: i18n.t('answers.ces.partially_disagree'), rating: '30' },
        { label: i18n.t('answers.ces.neutral'), rating: '50' },
        { label: i18n.t('answers.ces.partially_agree'), rating: '65' },
        { label: i18n.t('answers.ces.agree'), rating: '80' },
        { label: i18n.t('answers.ces.totally_agree'), rating: '100' }
      ]
    }
  }
  // NES ou CES options - END

  // NVS options
  if (type === 'nvs') {
    options = [
      { label: i18n.t('answers.nvs.nvs_op1'), rating: '100' },
      { label: i18n.t('answers.nvs.nvs_op2'), rating: '75' },
      { label: i18n.t('answers.nvs.nvs_op3'), rating: '50' },
      { label: i18n.t('answers.nvs.nvs_op4'), rating: '25' },
      { label: i18n.t('answers.nvs.nvs_op5'), rating: '0' }
    ]
  }
  // NVS options - END

  // KPI options
  if (type === 'kpi') {
    if (ui === 'yn') {
      options = [
        { label: i18n.t('answers.yes'), value: '100' },
        { label: i18n.t('answers.no'), value: '0' }
      ]
    }
    if (ui === 'ynd') {
      options = [
        { label: i18n.t('answers.yes'), value: '100' },
        { label: i18n.t('answers.no'), value: '0' },
        { label: i18n.t('answers.dont_know'), value: '50' }
      ]
    }
    if (ui === '5emo') {
      options = [
        { label: i18n.t('answers.excellent'), icon: '\uE603', value: '100' },
        { label: i18n.t('answers.very_good'), icon: '\uE600', value: '75' },
        { label: i18n.t('answers.satifying'), icon: '\uE606', value: '50' },
        { label: i18n.t('answers.bad'), icon: '\uE607', value: '25' },
        { label: i18n.t('answers.very_bad'), icon: '\uE608', value: '0' }
      ]
    }
  }
  if (type === 'enum') {
    options = question.options
  }
  // KPI options - END
  return options
}
export const responsesHelper = {
  getOptions
}
