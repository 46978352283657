<template>
  <div class="binds-optgroup">
    <binds-subheader>{{ label }}</binds-subheader>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BindsOptgroup',
  props: {
    label: String,
    disabled: Boolean
  },
  provide () {
    return {
      BindsOptgroup: {
        disabled: this.disabled
      }
    }
  }
}
</script>

<style lang="scss">
.binds-optgroup {
  .binds-subheader {
    text-transform: uppercase;
  }

  .binds-ripple.binds-list-item-content {
    padding-left: 24px;
  }
}
</style>
