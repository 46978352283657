<template>
  <div>
      <div class="content-5num">
        <app-form-rate
          v-model="inputValue"
          :min-label="data.question.startLabel"
          :max-label="data.question.endLabel"
          textColor="#fff"
          :bgColor="data.buttons"
          :labelColor="data.response_font_color || data.texts"
          :isGradient="data.question.gradient"
          :items="items"
          item-value="value"
          item-text="label"
          @rate-value="postResponse"
        />
      </div>
      <div class="submit-options-matrix" style="text-align: center;" v-if="buttonEnabled">
        <app-button
        :binds-ripple="false"
        :widget="true"
        class="binds-raised binds-primary"
        style="box-shadow: none;background: none;"
        :style="{'color': data.buttons}"
        v-on:click="postResponse('')">{{data.question.nextLabel}}</app-button>
      </div>
  </div>
</template>

<script>
import { widgetService } from '@/_services'
import { gradientMaker } from '@/_helpers'

export default {
  props: ['data'],
  data () {
    return {
      inputValue: null,
      buttonEnabled: true,
      items: [
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 }
      ]
    }
  },
  methods: {
    async postResponse (e) {
      const option = e.toString()
      const nesValues = [
        { rating: '0', label: '1' },
        { rating: '25', label: '2' },
        { rating: '50', label: '3' },
        { rating: '75', label: '4' },
        { rating: '100', label: '5' }
      ]
      const index = nesValues.findIndex(i => i.label === option)
      const rating = option !== '' ? nesValues[index].rating : ''

      if (this.data.is_to_post) {
        this.$root.$emit('setLoading', true)
        let dataToSend = ''
        rating !== '' ? dataToSend = { 'label': option, 'rating': rating, 'sending': this.data.sending } : dataToSend = { 'rating': rating, skip: true, 'sending': this.data.sending }
        let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, dataToSend)
        this.$root.$emit('questionUpdated', questionUpdated, rating)
      }
    }
  },
  mounted () {
    this.$root.$emit('setLoading', false)
  },
  created () {
    gradientMaker.checkGradientAndReturnTheColors(this.data.question, this.items)
    if (this.data.question.required === true) {
      this.buttonEnabled = false
    }
  }
}
</script>

<style lang="scss">
.content-5num {
  text-align: center !important;

  & .rating {
    width: 90% !important;
    display: flex !important;
    justify-content: center !important;

    & .rating__button {
      width: 26px !important;
      height: 26px !important;
      font-size: 14px !important;
      line-height: 26px !important;
    }
    .rating__label {
      top: 55px !important;
      margin: 0 -25px !important;
    }
  }
}
</style>
