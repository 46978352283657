<template>
  <div class="binds-animated-faster zoomIn">
    <div class="binds-content-1to7 content-10num" id="binds-mobile-1to7">
      <app-form-rate
        v-model="inputValue"
        :min-label="data.question.startLabel"
        :max-label="data.question.endLabel"
        textColor="#fff"
        :labelColor="data.response_font_color || data.texts"
        :bgColor="data.buttons"
        :isGradient="data.question.gradient"
        :items="options"
        item-value="rating"
        item-text="label"
        @rate-value="postResponse"
      />
    </div>
    <div class="submit-options-matrix" style="text-align: center;" v-if="buttonEnabled">
      <app-button
      :binds-ripple="false"
      widget="true"
      class="binds-raised binds-primary"
      style="box-shadow: none;background: none;"
      :style="{'color': data.buttons}"
      v-on:click="postResponse('')">{{data.question.nextLabel}}</app-button>
    </div>
  </div>
</template>

<script>
import { widgetService } from '@/_services'
import { gradientMaker } from '@/_helpers'
export default {
  props: ['data'],
  data () {
    return {
      inputValue: null,
      buttonEnabled: true,
      options: [
        { label: '1', rating: 0 },
        { label: '2', rating: 15 },
        { label: '3', rating: 30 },
        { label: '4', rating: 50 },
        { label: '5', rating: 65 },
        { label: '6', rating: 80 },
        { label: '7', rating: 100 }
      ]
    }
  },
  methods: {
    async postResponse (rating) {
      if (this.data.is_to_post) {
        this.$root.$emit('setLoading', true)
        let objectToSend = ''
        rating !== '' ? objectToSend = { rating, 'sending': this.data.sending } : objectToSend = { rating, skip: true, 'sending': this.data.sending }
        let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, objectToSend)
        this.$root.$emit('questionUpdated', questionUpdated, rating)
        this.$root.$emit('setLoading', false)
      }
    }
  },
  created () {
    this.$root.$emit('setLoading', false)
    gradientMaker.checkGradientAndReturnTheColors(this.data.question, this.options)
    if (this.data.question.required === true) {
      this.buttonEnabled = false
    }
  }
}
</script>
<style lang="scss">
@media screen and (max-width: 360px) {
 #binds-mobile-1to7 .rating .rating__button {
    width: 38px !important;
    height: 38px !important;
  }
}
.content-1to7button .rating__button {
  font-family: var(--binds_font_family) !important;
}
.binds-content-1to7 {
  padding-bottom: 15px;
}
.binds-content-1to7 .rating {
  .rating__button {
    width: 40px !important;
    height: 40px !important;
  }
}
</style>
