import Vue from 'vue'
import Router from 'vue-router'
// import Erro from './views/Erro.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/csat/:survey_id/:question',
      name: 'csat',
      component: require('./components/responseUi/Csat')
    },
    {
      path: '/nes/:survey_id/:question',
      name: 'nes',
      component: require('./components/responseUi/Nes')
    },
    {
      path: '/matrix/:survey_id/:question',
      name: 'matrix',
      component: require('./components/responseUi/Matrix')
    },
    {
      path: '/nps/:survey_id/:question',
      name: 'nps',
      component: require('./components/responseUi/Nps')
    },
    {
      path: '/enum/:survey_id/:question',
      name: 'enum',
      component: require('./components/responseUi/Enum')
    },
    {
      path: '/*',
      component: require('./App')
    }
  ],
  mode: 'history',
  linkActiveClass: 'active'
})
