<template>
  <transition name="binds-table-alternate-header">
    <div class="binds-table-alternate-header">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BindsTableAlternateHeader'
}
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-table-alternate-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    will-change: opacity, transform;
  }

  .binds-table-alternate-header-enter,
  .binds-table-alternate-header-leave-active {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  .binds-table-alternate-header-enter-active {
    transition: .3s $binds-transition-default-timing;
  }

  .binds-table-alternate-header-leave-active {
    transition: .2s $binds-transition-leave-timing;
  }
</style>
