import axios from 'axios'
import { setEvent } from '../_helpers/setEvents'

let API_URL = 'https://app.binds.co/api/'

export const widgetService = {
  getFirstQuestion,
  checkIfNextOrFirstQuestion,
  done
}

let cacheSending

/**
 * check if url received is wrong, inside v1 the Angular change the request url to https://admin.binds.co/app.binds.co and it broken the call
 */
function redirectRequest () {
  if (API_URL.match(/admin.binds.co/g)) {
    API_URL = 'https://app.binds.co/api/'
  }
  return API_URL
}

/**
 * Get first question of the survey for the current user
 *
 * @param {String} id contains the surveyId
 * @param {Object} body contains the metadata of user if exists, to check what is the first question of survey
 */
async function getFirstQuestion (id, body) {
  return callApiAndCheckUrl(API_URL + 'surveys/' + id + '/first-widget-question', body, 'post')
}

/**
 * send response of the next question to backend
 *
 * @param {String} id contains the sendingId
 * @param {Object} body contains the question response
 */
async function responseNextQuestionAndGetNext (id, body) {
  return callApiAndCheckUrl(`${API_URL}sendings/${id}/widget-responses`, body, 'post')
}

/**
 * send response of the first question to backend
 *
 * @param {String} id contains the surveyId
 * @param {Object} body contains the question response
 */
async function responseFirstQuestionAndGetNext (id, body) {
  return callApiAndCheckUrl(API_URL + 'surveys/' + id + '/first-widget-response', body, 'post')
}

/**
 * check if use first route response question or next question, and call the correctly
 *
 * @param {Object} data contains the data with question/sending details
 * @param {Object} objectToSend contains the data to send inside Post
 */
async function checkIfNextOrFirstQuestion (data, item) {
  let questionUpdated = ''
  let objectToSend = {}
  let isDone = false

  if (!data.sending) {
    objectToSend = {
      'seedData': {
        'metadata': JSON.parse(data.metadata),
        'from': JSON.parse(data.from)
      },
      'response': {
        ...item,
        'question': data.question._id
      }
    }
    questionUpdated = await responseFirstQuestionAndGetNext(data.survey_id, objectToSend)

    cacheSending = questionUpdated.sending
  } else {
    objectToSend = {
      ...item,
      'question': data.question._id
    }
    questionUpdated = await responseNextQuestionAndGetNext(data.sending, objectToSend)
  }

  if (questionUpdated.endMessage) { isDone = true }
  setEvent.sendResponseEvent(cacheSending, data.id, data.question._id, isDone)

  return questionUpdated
}

async function done (data) {
  return axios.get(`${API_URL}sendings/${data.sending}/done`).catch((error) => {
    const status = error && error.response && error.response.status

    return { status }
  })
}

async function callApiAndCheckUrl (url, body, type) {
  API_URL = redirectRequest()
  let result = {}
  if (type === 'post') {
    result = await axios
      .post(url, body)
  }
  return (result.data) ? result.data : {}
}
