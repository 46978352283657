export default {
  max_length: 'Máximo de carácteres: ',
  multiple_and: 'y',
  other_required: 'Complete el campo',
  multiple_answer_required: 'Debe seleccionar entre',
  multiple_options: 'opciones',
  answers: {
    yes: 'Sí',
    no: 'No',
    maybe: 'Tal vez',
    dont_know: 'No lo sé',
    excellent: 'Excelente',
    very_good: 'Muy bien',
    satifying: 'Satisfactorio',
    bad: 'Malo',
    very_bad: 'Muy malo',
    very_satisfied: 'Muy satisfecho',
    satisfied: 'Satisfecho',
    indifferent: 'Indiferente',
    unsatisfied: 'Insatisfecho',
    very_unsatisfied: 'Muy insatisfecho',
    ces: {
      totally_disagree: 'Desacuerdo totalmente',
      disagree: 'Estoy en desacuerdo',
      partially_disagree: 'Parcialmente en desacuerdo',
      neutral: 'Neutral',
      partially_agree: 'Parcialmente de acuerdo',
      agree: 'Estoy de acuerdo',
      totally_agree: 'Concuerdo totalmente'
    },
    nvs: {
      nvs_op1: 'Significativamente mejor',
      nvs_op2: 'Un poco mejor',
      nvs_op3: 'Ni mejor, ni peor',
      nvs_op4: 'Un poco peor',
      nvs_op5: 'Significativamente peor'
    },
    referral: {
      alert_email: 'Email inválido'
    }
  }
}
