<template>
  <div class="content-1to7button">
    <app-form-vertical-button
        v-model="inputValue"
        :min-label="data.question.startLabel"
        :max-label="data.question.endLabel"
        :textColor="data.response_font_color || data.texts"
        :labelColor="data.response_font_color || data.texts"
        :bgColor="data.buttons"
        :isGradient="data.question.gradient"
        :items="options"
        item-value="value"
        item-text="label"
        @rate-value="postResponse"
        id="binds-mobile-1to7button"
    />
    <div class="submit-options-matrix" style="text-align: center;" v-if="buttonEnabled">
      <app-button
      :binds-ripple="false"
      :widget="true"
      class="binds-raised binds-primary"
      style="box-shadow: none;background: none;"
      :style="{'color': data.buttons}"
      v-on:click="postResponse('')">{{data.question.nextLabel}}</app-button>
    </div>
  </div>
</template>

<script>
import { gradientMaker, translator } from '@/_helpers'
import { widgetService } from '@/_services'

export default {
  name: 'app1to7button',
  props: ['data'],
  components: {
    'app-form-vertical-button': () => import('@binds-tech/binds-design-system/src/components/Form/VerticalButton')
  },
  data () {
    return {
      inputValue: null,
      buttonEnabled: true
    }
  },
  created () {
    this.$i18n.locale = translator.getCurrentLocale(this.data.question.language)
    if (this.data.question.required === true) {
      this.buttonEnabled = false
    }
  },
  computed: {
    options () {
      const options = [
        { label: this.$t('answers.ces.totally_disagree'), value: 0 },
        { label: this.$t('answers.ces.disagree'), value: 15 },
        { label: this.$t('answers.ces.partially_disagree'), value: 30 },
        { label: this.$t('answers.ces.neutral'), value: 50 },
        { label: this.$t('answers.ces.partially_agree'), value: 65 },
        { label: this.$t('answers.ces.agree'), value: 80 },
        { label: this.$t('answers.ces.totally_agree'), value: 100 }
      ]
      gradientMaker.checkGradientAndReturnTheColors(this.data.question, options)
      return options
    }
  },
  methods: {
    async postResponse (rating) {
      if (this.data.is_to_post) {
        let objectToSend = ''
        rating !== '' ? objectToSend = { rating, 'sending': this.data.sending } : objectToSend = { rating, skip: true, 'sending': this.data.sending }
        objectToSend.value = rating !== '' ? this.options.find(s => s.value === rating).label : ''
        this.$root.$emit('setLoading', true)
        let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, objectToSend)
        this.$root.$emit('questionUpdated', questionUpdated, rating)
        this.$root.$emit('setLoading', false)
      }
    }
  }
}
</script>
<style lang="scss">
@media screen and (max-width: 360px) {
  div.content-1to7button {
    transform: scale(0.50) !important;
    margin-top: 25px;
    .rating__item {
      margin-bottom: 8px;
      .rating__button {
        width: 100% !important;
        font-size: 27px;
      }
    }
  }
}
.content-1to7button {
  transform: scale(0.95) !important;
  .rating__button {
    width: 100% !important;
    font-size: 20px;
  }
  .submit-options-matrix {
    margin-top: 20px;
    text-align: center;
  }
}
.next-question {
  width: 150%;
  margin-left: -50px;
  margin-top: 50px;
  display: flex;
  justify-content: right;
  .button {
    width: 130px;
    height: 40px;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
}
</style>
