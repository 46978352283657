<template>
  <div class="binds-menu" v-on="$listeners">
    <slot />
  </div>
</template>

<script>
import BindsPropValidator from '../../core/utils/BindsPropValidator'

export default {
  name: 'BindsMenu',
  props: {
    bindsActive: Boolean,
    bindsAlignTrigger: Boolean,
    bindsOffsetX: Number,
    bindsOffsetY: Number,
    bindsFullWidth: Boolean,
    bindsDense: Boolean,
    bindsDirection: {
      type: String,
      default: 'bottom-start',
      ...BindsPropValidator('binds-direction', [
        'top-end',
        'top-start',
        'bottom-end',
        'bottom-start'
      ])
    },
    bindsCloseOnSelect: {
      type: Boolean,
      default: true
    },
    bindsCloseOnClick: {
      type: Boolean,
      default: false
    },
    bindsSize: {
      type: String,
      default: 'small',
      ...BindsPropValidator('binds-size', [
        'auto',
        'small',
        'medium',
        'big',
        'huge'
      ])
    }
  },
  data () {
    return {
      triggerEl: null,
      BindsMenu: {
        instance: this,
        active: this.bindsActive,
        direction: this.bindsDirection,
        size: this.bindsSize,
        alignTrigger: this.bindsAlignTrigger,
        offsetX: this.bindsOffsetX,
        offsetY: this.bindsOffsetY,
        fullWidth: this.bindsFullWidth,
        dense: this.bindsDense,
        closeOnSelect: this.bindsCloseOnSelect,
        closeOnClick: this.bindsCloseOnClick,
        bodyClickObserver: null,
        windowResizeObserver: null,
        $el: this.$el
      }
    }
  },
  provide () {
    return {
      BindsMenu: this.BindsMenu
    }
  },
  computed: {
    isActive () {
      return this.BindsMenu.active
    }
  },
  watch: {
    bindsActive: {
      immediate: true,
      handler (isActive) {
        this.BindsMenu.active = isActive
      }
    },
    bindsDirection (direction) {
      this.BindsMenu.direction = direction
    },
    bindsSize (size) {
      this.BindsMenu.size = size
    },
    bindsAlignTrigger (aligned) {
      this.BindsMenu.alignTrigger = aligned
    },
    bindsOffsetX (offset) {
      this.BindsMenu.offsetX = offset
    },
    bindsOffsetY (offset) {
      this.BindsMenu.offsetY = offset
    },
    isActive (isActive) {
      this.$emit('update:bindsActive', isActive)

      if (!isActive) {
        this.$emit('binds-closed')
      } else {
        this.$emit('binds-opened')
      }
    },
    bindsCloseOnSelect () {
      this.BindsMenu.closeOnSelect = this.bindsCloseOnSelect
    },
    bindsCloseOnClick () {
      this.BindsMenu.closeOnClick = this.bindsCloseOnClick
    }
  },
  methods: {
    toggleContent ($event) {
      this.BindsMenu.active = !this.BindsMenu.active
    }
  },
  mounted () {
    this.BindsMenu.$el = this.$el

    this.$nextTick().then(() => {
      this.triggerEl = this.$el.querySelector('[binds-menu-trigger]')

      if (this.triggerEl) {
        this.triggerEl.addEventListener('click', this.toggleContent)
      }
    })
  },
  beforeDestroy () {
    if (this.triggerEl) {
      this.triggerEl.removeEventListener('click', this.toggleContent)
    }
  }
}
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";
  @import "../BindsLayout/mixins";

  .binds-menu {
    display: inline-block;

    > .binds-button {
      margin: 0;
    }
  }
</style>
