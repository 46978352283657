<template>
  <div>
    <!-- UI 5emo -->
    <div class="content-5emo" v-if="data.question.ui === '5emo'" id="binds-mobile-5emo">
      <div class="label-5emo hvr-grow" v-on:click="postResponse(100)">
        <old-5emo name="icon-joy-gee" size="40" color="#49BB59"></old-5emo>
        <span><h5 class="binds-heading" :style="{'color': data.response_font_color || data.texts}">{{ $t('answers.very_satisfied') }}</h5></span>
      </div>
      <div class="label-5emo hvr-grow" v-on:click="postResponse(75)">
        <old-5emo  name="icon-happy-gee" size="40" color="#37B0E4"></old-5emo>
        <span><h5 class="binds-heading" :style="{'color': data.response_font_color || data.texts}">{{ $t('answers.satisfied') }}</h5></span>
      </div>
      <div class="label-5emo hvr-grow" v-on:click="postResponse(50)">
        <old-5emo  name="icon-neutral-sleep" size="40" color="#9E9E9E"></old-5emo>
        <span><h5 class="binds-heading" :style="{'color': data.response_font_color || data.texts}">{{ $t('answers.indifferent') }}</h5></span>
      </div>
      <div class="label-5emo hvr-grow" v-on:click="postResponse(25)">
        <old-5emo  name="icon-unhappy-sleep" size="40" color="#F26C3C"></old-5emo>
        <span><h5 class="binds-heading" :style="{'color': data.response_font_color || data.texts}">{{ $t('answers.unsatisfied') }}</h5></span>
      </div>
      <div class="label-5emo hvr-grow" v-on:click="postResponse(0)">
        <old-5emo  name="icon-wow-open" size="40" color="#EE2E35"></old-5emo>
        <span><h5 class="binds-heading" :style="{'color': data.response_font_color || data.texts}">{{ $t('answers.very_unsatisfied') }}</h5></span>
      </div>
    </div>

    <!-- UI 5num e 10num -->
    <div
      v-if="data.question.ui === '5num' || data.question.ui === '10num'"
      :class="{
        'content-5num': data.question.ui === '5num',
        'content-10num': data.question.ui === '10num'
      }"
      class="binds-animated-faster zoomIn"
      id="binds-mobile-10num"
    >
      <app-form-rate
        @rate-value="postResponse"
        v-model="inputValue"
        :isStar="data.question.stars.active"
        :useStarNumbers="data.question.stars.numbers"
        :min-label="data.question.startLabel"
        :max-label="data.question.endLabel"
        textColor="#fff"
        :labelColor="data.response_font_color || data.texts"
        :bgColor="data.buttons"
        :items="returnOptions()"
        :isGradient="data.question.gradient"
        item-value="rating"
        item-text="value"
      />
    </div>
    <div class="submit-options-matrix" style="text-align: center;" v-if="buttonEnabled">
      <app-button
      :widget="true"
      :binds-ripple="false"
      class="binds-raised binds-primary"
      style="box-shadow: none;background: none;"
      :style="{'color': data.buttons}"
      v-on:click="postResponse('')">{{data.question.nextLabel}}</app-button>
    </div>
  </div>
</template>

<script>
import { widgetService } from '@/_services'
import { gradientMaker, translator } from '@/_helpers'

export default {
  props: ['data'],
  data () {
    return {
      buttonEnabled: true,
      inputValue: null,
      '10num': {
        options: [
          { rating: 0, value: '0' },
          { rating: 10, value: '1' },
          { rating: 20, value: '2' },
          { rating: 30, value: '3' },
          { rating: 40, value: '4' },
          { rating: 50, value: '5' },
          { rating: 60, value: '6' },
          { rating: 70, value: '7' },
          { rating: 80, value: '8' },
          { rating: 90, value: '9' },
          { rating: 100, value: '10' }
        ]
      },
      '5num': {
        options: [
          { rating: 0, value: '1' },
          { rating: 25, value: '2' },
          { rating: 50, value: '3' },
          { rating: 75, value: '4' },
          { rating: 100, value: '5' }
        ]
      },
      '5emo': {
        options: [
          { value: this.$t('answers.very_satisfied'), icon: '\uE603', rating: 100 },
          { value: this.$t('answers.satisfied'), icon: '\uE600', rating: 75 },
          { value: this.$t('answers.indifferent'), icon: '\uE606', rating: 50 },
          { value: this.$t('answers.unsatisfied'), icon: '\uE607', rating: 25 },
          { value: this.$t('answers.very_unsatisfied'), icon: '\uE608', rating: 0 }
        ]
      }
    }
  },
  created () {
    this.$root.$emit('setLoading', false)
    this.$i18n.locale = translator.getCurrentLocale(this.data.question.language)
    if (this.data.question.required === true) {
      this.buttonEnabled = false
    }
    if (this.data.question.stars === undefined) {
      this.data.question.stars = {}
    }
  },
  components: {
    'old-5emo': () => import('@binds-tech/binds-design-system/src/components/Icon/Old5emo')
  },
  methods: {
    returnOptions () {
      const item = this[this.data.question.ui].options
      if (!this.data.question.stars.active) {
        gradientMaker.checkGradientAndReturnTheColors(this.data.question, item)
      }
      return item
    },
    async postResponse (e) {
      const rating = e
      const value = e !== '' ? this[this.data.question.ui].options.filter(item => item.rating === e)[0].value : ''
      if (this.data.is_to_post) {
        this.$root.$emit('setLoading', true)
        let dataToSend = ''
        value !== '' || rating !== '' ? dataToSend = { value, rating, 'sending': this.data.sending } : dataToSend = { value, rating, skip: true, 'sending': this.data.sending }
        let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, dataToSend)
        this.$root.$emit('questionUpdated', questionUpdated, rating)
      }
    }
  }
}
</script>

<style lang="scss">
@media screen and (max-width: 430px) {
  #binds-mobile-10num.content-10num {
    .rating__item .rating__button {
      width: 26px !important;
      height: 26px !important;
    }
  }
  .content-10num {
    .rating__item .rating__button {
      width: 27px !important;
      height: 27px !important;
    }
  }
}
@media screen and (max-width: 360px) {
  div.content-5emo {
    margin-left: 15%;
    &#binds-mobile-5emo {
      // margin-left: 10%;
      // margin-top: 10px;
      // max-height: 11.5rem;
    }
    .label-5emo {
      .binds-heading {
        font-size: 16px !important;
      }
      .image-icon {
        font-size: 30px !important;
      }
    }
  }
  #binds-mobile-10num.content-10num {
    .rating__item .rating__button {
      width: 25px !important;
      height: 25px !important;
    }
  }
  .content-10num {
    .rating__item .rating__button {
      width: 25px !important;
      height: 25px !important;
    }
  }
}

div.content-10num, #binds-mobile-10num.content-5num {
  .rating__label {
    font-family: var(--binds_font_family);
  }
}

#binds-mobile-5emo.content-5emo {
  .label-5emo {
    min-width: 230px;
  }
  span > h5  {
    font-size: 17px;
    font-family: var(--binds_font_family);
    margin: 0;
  }
}

.content-5num {
  margin-bottom: 15px;
  .star-span {
    left: 19px !important;
    top: 20px !important;
  }

  & > div.rating {
    width: auto !important;

    span.rating__label {
      margin: 0 !important;
      font-family: var(--binds_font_family) !important;
    }
  }

  .rating {
    .rating__item .rating__button {
      width: 40px !important;
      height: 40px !important;
      font-size: 16px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.content-10num {
  font-family: var(--binds_font_family) !important;
  text-align: center !important;
  padding-top: 20px;
  & .rating__button {
    width: 30px !important;
    height: 30px !important;
    font-size: 14px !important;
    line-height: 33px !important;
  }
  & .rating__label {
    margin-top: 10px;
  }
  span {
    top: 35px !important;
    margin-bottom: 15px !important;
  }

@media (max-width: 390px) {
  .rating__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px !important;
    height: 25px !important;
    margin-right: 3px !important;
    span {
      font-size: 10px !important;
    }
  }
}
}
.purple-light {
  color: #9871dd;
}
.yellow-poker {
  color: #ffb500 !important;
}
.color-pink {
  color: rgb(255, 96, 125) !important;
}
.align-emojis {
  text-align: center !important;
}
.binds-animated-faster .binds-card-content {
  text-align: -webkit-center;
}
.spacing-csat {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 1rem;
  & i + i {
    margin-left: 35px;
  }
}
.alignSpecialLabels {
  text-align: right;
}
</style>
