<template>
  <binds-popover :binds-settings="popperSettings" binds-active>
    <transition
      name="binds-datepicker-dialog"
      appear
      @enter="setContentStyles"
      @after-leave="resetDate"
    >
      <div class="binds-datepicker-dialog" :class="[$bindsActiveTheme]">
        <div class="binds-datepicker-header">
          <span
            class="binds-datepicker-year-select"
            :class="{ 'binds-selected': currentView === 'year' }"
            @click="currentView = 'year'"
          >{{ selectedYear }}</span>
          <div
            class="binds-datepicker-date-select"
            :class="{ 'binds-selected': currentView !== 'year' }"
            @click="currentView = 'day'"
          >
            <strong class="binds-datepicker-dayname">{{ shortDayName }},</strong>
            <strong class="binds-datepicker-monthname">{{ shortMonthName }}</strong>
            <strong class="binds-datepicker-day">{{ currentDay }}</strong>
          </div>
        </div>

        <div class="binds-datepicker-body">
          <transition name="binds-datepicker-body-header">
            <div class="binds-datepicker-body-header" v-if="currentView === 'day'">
              <binds-button class="binds-dense binds-icon-button" @click="previousMonth">
                <binds-arrow-left-icon />
              </binds-button>

              <binds-button class="binds-dense binds-icon-button" @click="nextMonth">
                <binds-arrow-right-icon />
              </binds-button>
            </div>
          </transition>

          <div class="binds-datepicker-body-content" :style="contentStyles">
            <transition name="binds-datepicker-view">
              <transition-group
                class="binds-datepicker-panel binds-datepicker-calendar"
                :class="calendarClasses"
                tag="div"
                name="binds-datepicker-month"
                v-if="currentView === 'day'"
              >
                <div
                  class="binds-datepicker-panel binds-datepicker-month"
                  v-for="month in [currentDate]"
                  :key="month.getMonth()"
                >
                  <binds-button
                    class="binds-dense binds-datepicker-month-trigger"
                    @click="currentView = 'month'"
                  >{{ currentMonthName }} {{ currentYear }}</binds-button>

                  <div class="binds-datepicker-week">
                    <span
                      v-for="(day, index) in locale.shorterDays"
                      :key="index"
                    >
                      <span v-if="index >= firstDayOfAWeek">
                        {{ day }}
                      </span>
                    </span>
                    <span
                      v-for="(day, index) in locale.shorterDays"
                      :key="index"
                    >
                      <span v-if="index < firstDayOfAWeek">
                        {{ day }}
                      </span>
                    </span>
                  </div>

                  <div class="binds-datepicker-days">
                    <span
                      class="binds-datepicker-empty"
                      v-for="day in prefixEmptyDays"
                      :key="'day-empty-'+day"
                    ></span>
                    <div class="binds-datepicker-day" v-for="day in daysInMonth" :key="'day-'+day">
                      <span
                        class="binds-datepicker-day-button"
                        :class="{
                          'binds-datepicker-selected': isSelectedDay(day),
                          'binds-datepicker-today': isToday(day),
                          'binds-datepicker-disabled': isDisabled(day)
                        }"
                        @click="selectDate(day)"
                      >{{ day }}</span>
                    </div>
                  </div>
                </div>
              </transition-group>

              <div
                class="binds-datepicker-panel binds-datepicker-month-selector"
                v-else-if="currentView === 'month'"
              >
                <binds-button
                  class="binds-datepicker-year-trigger"
                  @click="currentView = 'year'"
                >{{ currentYear }}</binds-button>
                <span
                  class="binds-datepicker-month-button"
                  v-for="(month, index) in locale.months"
                  :class="{
                    'binds-datepicker-selected': currentMonthName === month
                  }"
                  :key="month"
                  @click="switchMonth(index)"
                >{{ month }}</span>
              </div>

              <keep-alive v-else-if="currentView === 'year'">
                <binds-content
                  class="binds-datepicker-panel binds-datepicker-year-selector binds-scrollbar"
                >
                  <span
                    class="binds-datepicker-year-button"
                    v-for="year in availableYears"
                    :class="{
                      'binds-datepicker-selected': currentYear === year
                    }"
                    :key="year"
                    @click="switchYear(year)"
                  >{{ year }}</span>
                </binds-content>
              </keep-alive>
            </transition>
          </div>

          <binds-dialog-actions class="binds-datepicker-body-footer">
            <binds-button class="binds-primary" @click="onCancel">Cancel</binds-button>
            <binds-button v-if="!bindsImmediately" class="binds-primary" @click="onConfirm">Ok</binds-button>
          </binds-dialog-actions>
        </div>
      </div>
    </transition>
  </binds-popover>
</template>

<script>
import addMonths from 'date-fns/addMonths'
import startOfMonth from 'date-fns/startOfMonth'
import subMonths from 'date-fns/subMonths'
import getDate from 'date-fns/getDate'
import getDay from 'date-fns/getDay'
import getDaysInMonth from 'date-fns/getDaysInMonth'
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'
import isEqual from 'date-fns/isEqual'
import isSameDay from 'date-fns/isSameDay'
import setDate from 'date-fns/setDate'
import setMonth from 'date-fns/setMonth'
import setYear from 'date-fns/setYear'

import BindsComponent from '../../core/BindsComponent'
import BindsPopover from '../BindsPopover/BindsPopover'
import BindsArrowRightIcon from '../../core/icons/BindsArrowRightIcon'
import BindsArrowLeftIcon from '../../core/icons/BindsArrowLeftIcon'
import BindsDialog from '../BindsDialog/BindsDialog'

const daysInAWeek = 7

const getElements = (el, selector) => {
  if (el && el.querySelector) {
    return el.querySelectorAll(selector)
  }

  return false
}

export default new BindsComponent({
  name: 'BindsDatepickerDialog',
  components: {
    BindsPopover,
    BindsArrowRightIcon,
    BindsArrowLeftIcon,
    BindsDialog
  },
  props: {
    bindsDate: Date,
    bindsDisabledDates: [Array, Function],
    bindsImmediately: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    currentDate: null,
    selectedDate: null,
    showDialog: false,
    monthAction: null,
    currentView: 'day',
    contentStyles: {},
    availableYears: null
  }),
  computed: {
    firstDayOfAWeek () {
      // normalize
      let firstDayOfAWeek = Number(this.locale.firstDayOfAWeek)
      if (Number.isNaN(firstDayOfAWeek) || !Number.isFinite(firstDayOfAWeek)) {
        return 0
      }
      firstDayOfAWeek = Math.floor(firstDayOfAWeek) % daysInAWeek
      firstDayOfAWeek += firstDayOfAWeek < 0 ? daysInAWeek : 0
      return firstDayOfAWeek
    },
    locale () {
      return this.$material.locale
    },
    popperSettings () {
      return {
        placement: 'bottom-start',
        modifiers: {
          keepTogether: {
            enabled: true
          },
          flip: {
            enabled: false
          }
        }
      }
    },
    calendarClasses () {
      if (this.monthAction === 'next') {
        return 'binds-next'
      }

      return 'binds-previous'
    },
    firstDayOfMonth () {
      return startOfMonth(this.currentDate).getDay()
    },
    prefixEmptyDays () {
      let prefixEmptyDays = this.firstDayOfMonth - this.firstDayOfAWeek
      prefixEmptyDays += prefixEmptyDays < 0 ? daysInAWeek : 0
      return prefixEmptyDays
    },
    daysInMonth () {
      return getDaysInMonth(this.currentDate)
    },
    currentDay () {
      if (this.selectedDate) {
        return getDate(this.selectedDate)
      }

      return getDate(this.currentDate)
    },
    currentMonth () {
      return getMonth(this.currentDate)
    },
    currentMonthName () {
      return this.locale.months[this.currentMonth]
    },
    currentYear () {
      return getYear(this.currentDate)
    },
    selectedYear () {
      if (this.selectedDate) {
        return getYear(this.selectedDate)
      }

      return getYear(this.currentDate)
    },
    shortDayName () {
      if (this.selectedDate) {
        return this.locale.shortDays[getDay(this.selectedDate)]
      }

      return this.locale.shortDays[getDay(this.currentDate)]
    },
    shortMonthName () {
      if (this.selectedDate) {
        return this.locale.shortMonths[getMonth(this.selectedDate)]
      }

      return this.locale.shortMonths[getMonth(this.currentDate)]
    }
  },
  watch: {
    bindsDate () {
      this.currentDate = this.bindsDate || new Date()
      this.selectedDate = this.bindsDate
    },
    currentDate (next, previous) {
      this.$nextTick().then(() => {
        if (previous) {
          this.setContentStyles()
        }
      })
    },
    currentView () {
      this.$nextTick().then(() => {
        if (this.currentView === 'year') {
          const activeYear = getElements(
            this.$el,
            '.binds-datepicker-year-button.binds-datepicker-selected'
          )

          if (activeYear.length) {
            activeYear[0].scrollIntoView({
              behavior: 'instant',
              block: 'center',
              inline: 'center'
            })
          }
        }
      })
    }
  },
  methods: {
    setContentStyles () {
      const months = getElements(this.$el, '.binds-datepicker-month')

      if (months.length) {
        const nextMonth = months[months.length - 1]

        this.contentStyles = {
          height: nextMonth.offsetHeight + 10 + 'px'
        }
      }
    },
    setAvailableYears () {
      const { startYear, endYear } = this.locale
      let counter = startYear
      let years = []

      while (counter <= endYear) {
        years.push(counter++)
      }

      this.availableYears = years
    },
    handleDisabledDateByArray (date) {
      return this.bindsDisabledDates.some(disabledDate =>
        isSameDay(disabledDate, date)
      )
    },
    isDisabled (day) {
      if (this.bindsDisabledDates) {
        const targetDate = setDate(this.currentDate, day)

        if (Array.isArray(this.bindsDisabledDates)) {
          return this.handleDisabledDateByArray(targetDate)
        } else if (typeof this.bindsDisabledDates === 'function') {
          return this.bindsDisabledDates(targetDate)
        }
      }
    },
    isSelectedDay (day) {
      return isEqual(this.selectedDate, setDate(this.currentDate, day))
    },
    isToday (day) {
      return isSameDay(new Date(), setDate(this.currentDate, day))
    },
    previousMonth () {
      this.monthAction = 'previous'
      this.currentDate = subMonths(this.currentDate, 1)
    },
    nextMonth () {
      this.monthAction = 'next'
      this.currentDate = addMonths(this.currentDate, 1)
    },
    switchMonth (index) {
      this.currentDate = setMonth(this.currentDate, index)
      this.currentView = 'day'
    },
    switchYear (year) {
      this.currentDate = setYear(this.currentDate, year)
      this.currentView = 'month'
    },
    selectDate (day) {
      this.currentDate = setDate(this.currentDate, day)
      this.selectedDate = this.currentDate

      if (this.bindsImmediately) {
        this.$emit('update:bindsDate', this.selectedDate)
        this.closeDialog()
      }
    },
    closeDialog () {
      this.$emit('binds-closed')
    },
    onClose () {
      this.closeDialog()
    },
    onCancel () {
      this.closeDialog()
    },
    onConfirm () {
      this.$emit('update:bindsDate', this.selectedDate)
      this.closeDialog()
    },
    resetDate () {
      this.currentDate = this.bindsDate || new Date()
      this.selectedDate = this.bindsDate
      this.currentView = 'day'
    }
  },
  created () {
    this.setAvailableYears()
    this.resetDate()
  }
})
</script>

<style lang="scss">
@import "../BindsAnimation/variables";
@import "../BindsLayout/mixins";
@import "../BindsElevation/mixins";

$binds-calendar-width: 320px;
$binds-calendar-mobile-width: 296px;

.binds-datepicker-dialog {
  @include binds-elevation(24);
  display: flex;
  overflow: hidden;
  z-index: 110;
  border-radius: 2px;
  backface-visibility: hidden;
  pointer-events: auto;
  transform-origin: top left;
  flex-shrink: 0;
  transition: opacity 0.2s $binds-transition-stand-timing,
    transform 0.35s $binds-transition-stand-timing;
  will-change: opacity, transform, left, top;

  @include binds-layout-xsmall {
    flex-direction: column;
    top: 50% !important;
    left: 50% !important;
    transform: translate3D(-50%, -50%, 0);
    transform-origin: center center;
    position: fixed !important;
  }
}

.binds-datepicker-dialog-leave-active {
  opacity: 0;
}

.binds-datepicker-dialog-enter {
  opacity: 0;
  transform: scale(0.9);

  @include binds-layout-xsmall {
    transform: translate3D(-50%, -50%, 0) scale(0.9);
  }

  .binds-datepicker-body {
    .binds-datepicker-calendar {
      opacity: 0;
      transform: translate3D(0, 10%, 0);
    }
  }
}

.binds-datepicker-header {
  min-width: 150px;
  padding: 16px;

  @include binds-layout-xsmall {
    min-width: auto;
    padding: 16px 20px;
  }

  .binds-datepicker-year-select {
    cursor: pointer;
    opacity: 0.54;
    transition: opacity 0.3s $binds-transition-default-timing;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.01em;
    line-height: 24px;
  }

  .binds-datepicker-date-select {
    cursor: pointer;
    opacity: 0.54;
    transition: opacity 0.3s $binds-transition-default-timing;
    font-size: 32px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 1.2em;
  }

  .binds-datepicker-dayname {
    display: block;

    @include binds-layout-xsmall {
      display: inline-block;
    }
  }

  .binds-selected {
    opacity: 1;
  }
}

.binds-datepicker-body {
  width: $binds-calendar-width;
  position: relative;
  overflow: hidden;
  transition: width 0.3s $binds-transition-stand-timing;
  will-change: width;

  @include binds-layout-xsmall {
    width: $binds-calendar-mobile-width;
  }

  .binds-button {
    margin: 0;
  }
}

.binds-datepicker-body-header {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  pointer-events: none;

  &:before,
  &:after {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 0;
    z-index: 2;
    pointer-events: none;
    content: " ";
  }

  &:after {
    left: 0;
  }

  &:before {
    right: 0;
  }

  .binds-button {
    pointer-events: auto;
    z-index: 3;
  }
}

.binds-datepicker-body-header-enter,
.binds-datepicker-body-header-leave-active {
  .binds-button:first-child {
    transform: translate3d(-150%, 0, 0);
  }

  .binds-button:last-child {
    transform: translate3d(150%, 0, 0);
  }
}

.binds-datepicker-body-content {
  overflow: hidden;
  transition: height 0.35s $binds-transition-default-timing;
  will-change: height;
}

.binds-datepicker-panel {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: 0.35s $binds-transition-default-timing;
  transition-property: transform, opacity;
  will-change: transform, opacity;
}

.binds-datepicker-calendar {
  &.binds-datepicker-view-enter,
  &.binds-datepicker-view-leave-active {
    transform: translate3d(0, 100%, 0);
  }

  &.binds-previous {
    .binds-datepicker-month-enter {
      transform: translate3D(-100%, 0, 0);

      .binds-datepicker-month-trigger {
        transform: translate3D(-30%, 0, 0);
      }
    }

    .binds-datepicker-month-leave-active {
      transform: translate3D(100%, 0, 0);
    }
  }

  &.binds-next {
    .binds-datepicker-month-enter {
      transform: translate3D(100%, 0, 0);

      .binds-datepicker-month-trigger {
        transform: translate3D(30%, 0, 0);
      }
    }

    .binds-datepicker-month-leave-active {
      transform: translate3D(-100%, 0, 0);
    }
  }
}

.binds-datepicker-month {
  top: 8px;
  bottom: auto;
  flex-direction: column;
  transition: 0.35s $binds-transition-default-timing;
  transition-property: transform, opacity;
  will-change: transform, opacity;

  @include binds-layout-xsmall {
    padding: 0 6px;
  }

  .binds-datepicker-month-trigger {
    min-height: 32px;
    margin: 0 46px 10px;
    flex: 1;
    border-radius: 0;
    transition: transform 0.45s $binds-transition-default-timing;
    will-change: transform;
  }
}

.binds-datepicker-week {
  display: flex;
  align-items: center;

  span {
    flex: 1;
    font-size: 12px;
    text-align: center;
  }
}

.binds-datepicker-days {
  $binds-day-width: 100%;

  display: flex;
  flex-wrap: wrap;

  .binds-datepicker-empty,
  .binds-datepicker-day {
    margin: 1px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 1 $binds-day-width / 7;
  }

  .binds-datepicker-day-button {
    $width: 30px;

    width: $width;
    min-width: $width;
    height: $width;
    cursor: pointer;
    border-radius: $width;
    transition: 0.3s $binds-transition-default-timing;
    line-height: $width;
    text-align: center;
  }

  .binds-datepicker-selected {
    font-weight: 700;
  }

  .binds-datepicker-today {
    font-weight: 700;
  }

  .binds-datepicker-disabled {
    pointer-events: none;
  }
}

.binds-datepicker-month-selector {
  padding: 6px 8px 10px;
  flex-wrap: wrap;
  bottom: auto;
  transition: 0.35s $binds-transition-default-timing;
  transition-property: transform, opacity;
  will-change: transform, opacity;

  &.binds-datepicker-view-enter,
  &.binds-datepicker-view-leave-active {
    transform: translate3d(0, -100%, 0);
  }

  .binds-datepicker-year-trigger {
    width: 100%;
    margin: 0 0 8px;
    flex: 1 1 100%;
  }
}

.binds-datepicker-month-button,
.binds-datepicker-year-button {
  height: 36px;
  margin: 3px 0;
  cursor: pointer;
  transition: 0.3s $binds-transition-default-timing;
  line-height: 36px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.binds-datepicker-month-button {
  flex: 1 1 33.3333%;
  border-radius: 2px;
  font-size: 13px;
}

.binds-datepicker-year-selector {
  flex-direction: column;
  overflow: auto;
  bottom: 52px;
  border-bottom: 1px solid;

  &.binds-datepicker-view-enter,
  &.binds-datepicker-view-leave-active {
    transform: translate3d(0, -100%, 0);
  }

  .binds-button {
    min-height: 36px;
  }
}

.binds-datepicker-year-button {
  font-size: 16px;

  &.binds-datepicker-selected {
    font-size: 24px;
  }
}
</style>
