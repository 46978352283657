<template>
  <binds-empty-state v-bind="$props" class="binds-table-empty-state">
    <slot />
  </binds-empty-state>
</template>

<script>
import BindsEmptyStateProps from '../BindsEmptyState/BindsEmptyStateProps'

export default {
  name: 'BindsTableEmptyState',
  props: BindsEmptyStateProps,
  inject: ['BindsTable']
}
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-table-empty-state {
    padding-left: 24px;
  }
</style>
