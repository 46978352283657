<template>
  <div>
    <div class="binds-animated-faster zoomIn">
      <div class="binds-content-input-text" v-if="data.question.mask === null">
        <app-form-textarea
          v-model="message"
          color="#080808"
          :placeholder="data.question.placeholder"
          :minlenght="minLength()"
        ></app-form-textarea>
        <p class="binds-text-counter binds-heading" :style="{'color': data.response_font_color || data.texts}">
          {{ $t('max_length') }}
          <span :style="{'color': textRemaining.remainingCount === 0 ? 'red' : data.response_font_color || data.texts}">
            {{ textRemaining.remainingCount }}
          </span>
        </p>
      </div>
      <div class="binds-content-input-text" v-if="data.question.mask !== null">
        <app-form-input
          color="#080808"
          :placeholder="data.question.placeholder"
          v-model="message"
          required
          secondary
          border-solid
          :minlenght="minLength()"
          @keyup="validatePhone($event)"
        ></app-form-input>
        <div><p class="binds-alert-message" v-if="alertMessage">{{alertMessage}}</p></div>
      </div>
    </div>
    <div class="submit-options-matrix" style="text-align: center;" v-if="showButton">
      <app-button
        :binds-ripple="false"
        :widget="true"
        class="binds-raised binds-primary"
        style="box-shadow: none;background: none;"
        :style="{'color': data.buttons}"
        v-on:click="save('')"
        :minlenght="minLength()"
      >{{ data.question.nextLabel }}</app-button>
    </div>
  </div>
</template>

<script>
import { widgetService } from '@/_services'

export default {
  props: ['data'],
  data () {
    return {
      message: '',
      alertMessage: false,
      showButton: false,
      textRemaining: {
        maxCount: this.data.question.maxLength !== 0 ? this.data.question.maxLength : 750,
        remainingCount: this.data.question.maxLength !== 0 ? this.data.question.maxLength : 750
      }
    }
  },
  mounted () {
    if (!this.data.question.required) { this.showButton = true }
  },
  watch: {
    message (newValue) {
      const newTextValue = newValue.replace(/[^A-zÀ-ú \n 0-9]/gi, '') // regex para desconsiderar emoticons na contagem
      const lengthValue = this.data.question.maxLength !== 0 ? this.data.question.maxLength : 750
      if (newTextValue.length <= lengthValue) {
        this.message = newValue
      } else {
        this.message = newValue.substring(0, lengthValue)
      }
      this.textValue()
    }
  },
  methods: {
    validatePhone (evt) {
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if (((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) && this.data.question.mask === 'tel') {
        this.message = this.message.replace(/[^\d\s+|\-,]/g, '')
        evt.preventDefault()
      } else {
        return true
      }
    },
    buttonEnabled () {
      const newTextValue = this.message.replace(/[^A-zÀ-ú \n 0-9]/gi, '')
      this.textRemaining.remainingCount = this.textRemaining.maxCount - newTextValue.length
      if (this.data.question.required === true && (this.textRemaining.remainingCount >= this.data.question.minLength)) {
        return true
      } else {
        return false
      }
    },
    minLength () {
      let length
      if (this.data.question.required) {
        length = this.data.question.minLength.toString()
      } else {
        length = '0'
      }
      return length
    },
    textValue () {
      const newTextValue = this.message.replace(/[^A-zÀ-ú \n 0-9]/gi, '')
      this.textRemaining.remainingCount = this.textRemaining.maxCount - newTextValue.length
      if (newTextValue.length <= 0 && this.data.question.required) {
        this.showButton = false
      } else if (this.data.question.required || (newTextValue.length >= this.data.question.minLength)) {
        this.showButton = true
      }
    },
    save () {
      let refError = false
      let objectToPost = ''
      // validate email
      if (this.data.question.mask === 'email') {
        if (this.message && this.message.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) === null) {
          this.alert(this.$i18n.t('answers.referral.alert_email'))
          refError = true
        }
      }
      if (this.data.is_to_post && !refError) {
        this.$root.$emit('setLoading', true)
        objectToPost = { 'text': this.message, 'sending': this.data.sending, rating: 100 }
        this.submitEvent(objectToPost)
      }
    },
    async submitEvent (content) {
      let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, content)
      this.$root.$emit('questionUpdated', questionUpdated)
      this.$root.$emit('setLoading', false)
      this.message = ''
    },
    alert (message) {
      this.alertMessage = message
    }
  }
}
</script>

<style lang="scss">
@media screen and (max-width: 420px ) {
  .binds-content-input-text {
    width: 21rem !important;
  }
  .submit-options-matrix {
    padding: 1rem;
  }
}
@media screen and (max-width: 380px) {
  .binds-content-input-text {
    width: 20rem !important;
  }
}
@media screen and (max-width: 360px) {
  .binds-content-input-text {
    width: 17.5rem !important;
  }
}

.binds-text-counter {
  display: flex;
  place-content: center;
  margin: 20px 0 5px !important;
}
.submit-text {
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: right;
}
.binds-content-input-text{
  padding: 10px 25px;
  width: 24rem;
  & .input-wrapper textarea.input {
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
  }
  & .input-wrapper .input {
    height: 145px;
  }
  & .text-field {
    display: block;
    .text-field__input {
      margin: 20px 0;
      border: 1px solid #cacaca;
      border-radius: 3px;
      padding-left: 10px;
    }
  }
  p.binds-alert-message {
    font-family: var(--binds_font_family);
  }
  p.binds-text-counter {
    font-family: var(--binds_font_family);
  }
}
.binds-content-input-text .binds-input{
  text-indent: 12px;
}

</style>
