<template>
  <div class="binds-nvs-create">
    <ul>
      <li v-for="(option, idx) in options" :key="idx" @click="postResponse(option.label, option.rating)">
        <app-form-radio class="single-radio"/>
        <h6 class="binds-heading" :style="{ color: data.response_font_color || data.texts}">{{ option.label }}</h6>
      </li>
    </ul>
    <div class="submit-options-matrix" style="text-align: center;" v-if="buttonEnabled">
      <app-button
      :binds-ripple="false"
      :widget="true"
      class="binds-raised binds-primary"
      style="box-shadow: none;background: none;"
      :style="{'color': data.buttons}"
      v-on:click="postResponse('', '')">{{data.question.nextLabel}}</app-button>
    </div>
  </div>
</template>

<script>
import { widgetService } from '@/_services'
import { translator } from '@/_helpers'

export default {
  name: 'app5radio',
  props: ['data'],
  components: {
    'app-form-radio': () => import('@binds-tech/binds-design-system/src/components/Form/Radio')
  },
  data () {
    return {
      buttonEnabled: true
    }
  },
  computed: {
    options () {
      const options = [
        { label: this.$t('answers.nvs.nvs_op1'), rating: 100 },
        { label: this.$t('answers.nvs.nvs_op2'), rating: 75 },
        { label: this.$t('answers.nvs.nvs_op3'), rating: 50 },
        { label: this.$t('answers.nvs.nvs_op4'), rating: 25 },
        { label: this.$t('answers.nvs.nvs_op5'), rating: 0 }
      ]
      return options
    }
  },
  created () {
    this.$i18n.locale = translator.getCurrentLocale(this.data.question.language)
    if (this.data.question.required === true) {
      this.buttonEnabled = false
    }
  },
  methods: {
    async postResponse (value, rating) {
      if (this.data.is_to_post) {
        this.$root.$emit('setLoading', true)
        let objectToSend = ''
        rating !== '' ? objectToSend = { value, rating, 'sending': this.data.sending } : objectToSend = { rating, skip: true, 'sending': this.data.sending }
        let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, objectToSend)
        this.$root.$emit('questionUpdated', questionUpdated, rating)
        this.$root.$emit('setLoading', false, rating)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.binds-nvs-create {
  display: flex;
  place-content: center;
  width: 100%;
  margin-top: 10px;
  ul {
    margin-top: 30px;
    li {
      display: flex;
      align-items: center;
      height: 25px;
      margin-bottom: 20px;
      cursor: pointer;
      div.single-radio {
        width: 10% !important;
        margin: 0 !important;
      }
      .binds-heading {
        text-align: left;
        margin: 7px 0 0 15px;
        font-family: var(--binds_font_family);
        font-size: 17px;
        font-weight: 700;
      }
    }
  }
  .next-question {
    margin-top: 40px;
    display: flex;
    justify-content: right;
    .button {
      width: 110px;
      height: 30px;
      font-size: 14px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
