<template>
<div>
    <div>
        <!-- UI 5emo -->
      <div class="content-5emo" v-if="data.question.ui === '5emo'">
        <div class="label-5emo hvr-grow" v-on:click="postResponse(100, $t('answers.excellent'))">
          <old-5emo name="icon-joy-gee" size="40" color="#49BB59"></old-5emo>
          <span><h5 class="binds-heading" :style="{ 'color': data.response_font_color || data.texts}">{{ $t('answers.excellent') }}</h5></span>
        </div>
        <div class="label-5emo hvr-grow" v-on:click="postResponse(75, $t('answers.very_good'))">
          <old-5emo  name="icon-happy-gee" size="40" color="#37B0E4"></old-5emo>
          <span><h5 class="binds-heading" :style="{ 'color': data.response_font_color || data.texts}">{{ $t('answers.very_good') }}</h5></span>
        </div>
        <div class="label-5emo hvr-grow" v-on:click="postResponse(50, $t('answers.satifying'))">
          <old-5emo  name="icon-neutral-sleep" size="40" color="#9E9E9E"></old-5emo>
          <span><h5 class="binds-heading" :style="{ 'color': data.response_font_color || data.texts}">{{ $t('answers.satifying') }}</h5></span>
        </div>
        <div class="label-5emo hvr-grow" v-on:click="postResponse(25, $t('answers.bad'))">
          <old-5emo  name="icon-unhappy-sleep" size="40" color="#F26C3C"></old-5emo>
          <span><h5 class="binds-heading" :style="{ 'color': data.response_font_color || data.texts}">{{ $t('answers.bad') }}</h5></span>
        </div>
        <div class="label-5emo hvr-grow" v-on:click="postResponse(0, $t('answers.very_bad'))">
          <old-5emo  name="icon-wow-open" size="40" color="#EE2E35"></old-5emo>
          <span><h5 class="binds-heading" :style="{ 'color': data.response_font_color || data.texts}">{{ $t('answers.very_bad') }}</h5></span>
        </div>
      </div>

      <!-- UI yn -->
      <div class="row center-lg center-md center-sm op-yn-wid binds-animated-faster zoomIn" v-if="data.question.ui === 'yn'">
        <app-button :widget="true" :binds-ripple="false" style="background: #57ad26; box-shadow: 0 5px 15px 0 #d7d7d7 !important;" class="binds-raised bind-space-between-buttons" variation="primary" v-on:click.native="postResponse(100)">{{ $t('answers.yes') }}</app-button>
        <app-button :widget="true" :binds-ripple="false" style="background: #ea484d; box-shadow: 0 5px 15px 0 #d7d7d7 !important;" class="binds-raised bind-space-between-buttons" variation="secondary" v-on:click.native="postResponse(0)">{{ $t('answers.no') }}</app-button>
      </div>

      <!-- UI ynm -->
        <div class="row center-lg center-md center-sm op-ynd-wid binds-animated-faster zoomIn" v-if="data.question.ui === 'ynd'">
          <app-button :widget="true" :binds-ripple="false" style="background: #57ad26; box-shadow: 0 5px 15px 0 #d7d7d7 !important;" class="binds-raised bind-space-between-buttons" variation="primary" v-on:click.native="postResponse(100)">{{ $t('answers.yes') }}</app-button>
          <app-button :widget="true" :binds-ripple="false" style="background: #ea484d; box-shadow: 0 5px 15px 0 #d7d7d7 !important;" class="binds-raised bind-space-between-buttons" variation="secondary" v-on:click.native="postResponse(0)">{{ $t('answers.no') }}</app-button>
          <app-button :widget="true" :binds-ripple="false" style="background: #f8c43d !important; color: #ffffff !important; box-shadow: 0 5px 15px 0 #d7d7d7 !important;" class="binds-raised bind-space-between-buttons maybe" v-on:click.native="postResponse(50)">{{ $t('answers.dont_know') }}</app-button>
        </div>
        <div class="submit-options-matrix" style="text-align: center;" v-if="buttonEnabled">
          <app-button
          :binds-ripple="false"
          :widget="true"
          class="binds-raised binds-primary"
          style="box-shadow: none;background: none;"
          :style="{'color': data.buttons}"
          v-on:click="postResponse('')">{{data.question.nextLabel}}</app-button>
        </div>
    </div>
  </div>
</template>

<script>
import { widgetService } from '@/_services'

export default {
  props: ['data'],
  data () {
    return {
      buttonEnabled: true
    }
  },
  components: {
    'old-5emo': () => import('@binds-tech/binds-design-system/src/components/Icon/Old5emo')
  },
  methods: {
    async postResponse (rating, label) {
      let objectToPost = ''
      rating !== '' ? objectToPost = { 'rating': rating, 'sending': this.data.sending } : objectToPost = { 'rating': rating, skip: true, 'sending': this.data.sending }
      if (label) {
        objectToPost.label = label
      }
      if (this.data.is_to_post) {
        this.$root.$emit('setLoading', true)
        let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, objectToPost)
        this.$root.$emit('questionUpdated', questionUpdated, rating)
      }
    }
  },
  mounted () {
    if (this.data.question.required === true) {
      this.buttonEnabled = false
    }
    this.$root.$emit('setLoading', false)
  }
}
</script>
<style lang="scss">
.content-5emo {
  width: 200px;
  margin: 0 auto;
  margin-top: 30px;
  .hvr-grow {
    display: block;
  }
  .label-5emo{
    margin: 10px 0;
    cursor: pointer;
    span{
      display: inline-block;
      vertical-align: super;
      margin-left: 10px;
      h5 {
        font-family: var(--binds_font_family);
        margin: 0;
        font-size: 17px;
        font-weight: 700;
      }
    }
  }
}

.spacing-kpi {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 4rem;
  & i + i {
    margin-left: 35px;
  }
}
.binds-emo-kpi {
  margin-top: 2rem;
  margin-bottom: 2rem;
  & i + i {
    margin-left: 30px;
  }
}
.op-yn-wid {
  width: 300px !important;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  & button {
    width: 245px;
    border-radius: 24px;
    display: block;
    font-family: var(--binds_font_family);
  }
}

.op-ynd-wid {
  width: 300px !important;
  text-align: center;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  & button {
    width: 240px;
    border-radius: 24px !important;
    font-family: var(--binds_font_family);
  }
  & .maybe {
    color: #504a4a !important;
    background-color: transparent !important;
  }
}
.purple-light {
  color: #9871dd;
}
.yellow-poker {
  color: #ffb500 !important;
}
.color-pink {
  color: rgb(255, 96, 125) !important;
}
.content-5num {
  padding: 20px;
}
.data5emo {
  width: 52%;
  margin: 0 auto;
  display: flex;
}
.data5emo i {
  display: inline-block;
  font-size: 20px;
  float: left;
}
.data5emo span {
  margin-left: 4%;
  display: inline-block;
  font-size: 16px;
}
.content-5num .binds-card {
  margin-bottom: 10px;
}
</style>
