<template>
  <div class="binds-animated-faster zoomIn">
    <div :class="this.options.length > 6 ? 'content-10num' : 'content-5num'">
      <app-form-rate
        v-model="inputValue"
        :min-label="data.question.startLabel"
        :max-label="data.question.endLabel"
        textColor="#fff"
        :labelColor="data.response_font_color || data.texts"
        :bgColor="data.buttons"
        :isGradient="data.question.gradient"
        :items="options"
        item-value="value"
        item-text="label"
        @rate-value="postResponse"
      />
    </div>
  </div>
</template>

<script>
import { widgetService } from '@/_services'
import { gradientMaker } from '@/_helpers'
export default {
  props: ['data'],
  data () {
    return {
      inputValue: null,
      options: {}
    }
  },
  methods: {
    async postResponse (e) {
      let objectToSend = { rating: e, 'sending': this.data.sending }
      if (this.data.question.type === 'enum') {
        objectToSend = { value: e, isOther: false, text: '', 'sending': this.data.sending }
      }
      if (this.data.is_to_post) {
        this.$root.$emit('setLoading', true)
        let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, objectToSend)
        this.$root.$emit('questionUpdated', questionUpdated, e)
        this.$root.$emit('setLoading', false)
      }
    }
  },
  created () {
    this.$root.$emit('setLoading', false)
    let options = []
    if (this.data.question.options.length > 0) {
      this.data.question.options.map(function (i) {
        options.push({ value: parseInt(i.label), label: i.label })
      })
      this.options = options
    }
    gradientMaker.checkGradientAndReturnTheColors(this.data.question, this.options)
  }
}
</script>
<style lang="scss">
.content-5num {
  display: flex;
  justify-content: center;
}
.content-10num {
  .rating {
    .rating__button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
