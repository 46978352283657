<template>
  <div v-if="isToShow && !contentPermaClosed">
      <div class="binds-shadow-popup"
        v-if=" data.widget_position === 'popup' && bindsOpenWidget && isToShow"
        :style="{'position': is_preview ? 'absolute !important' : 'fixed !important'}">
      </div>
      <div class="binds-content-widget"
        :class="{
          'binds-right': data.widget_position === 'right', // old param
          'binds-left': data.widget_position === 'left', // old param
          'binds-top-right': data.widget_position === 'top-right',
          'binds-middle-right': data.widget_position === 'middle-right',
          'binds-bottom-right': data.widget_position === 'bottom-right',
          'binds-top-left': data.widget_position === 'top-left',
          'binds-middle-left': data.widget_position === 'middle-left',
          'binds-bottom-left': data.widget_position === 'bottom-left',
          'binds-lower': data.widget_position === 'lower',
          'binds-popup': data.widget_position === 'popup',
          'binds-action': data.widget_position === 'actionButton',
          'binds-actionB': data.widget_position === 'actionButtonB',
        }"
        :style="{
          'width': !arrowToOpen ? data.width + data.width_metric : '0px',
          'position': is_preview ? 'absolute !important' : 'fixed !important',
          'min-height': !arrowToOpen ? '150px' : 0,
          'min-width': !arrowToOpen ? '416px' : 0,
        }">
        <div>
          <button
          v-if="(data.widget_position.includes('right') || data.widget_position.includes('left')) && arrowToOpen"
          class="bindsOpenWidget binds-elevation-5"
          v-on:click="bindsOpenWidget = !bindsOpenWidget; arrowToOpen = false"
          :style="{'background-color': background}"
          :class="{
            'binds-animated': bindsOpenWidget,
            'binds-button-right' : data.widget_position.includes('right'),
            'binds-button-left' : data.widget_position.includes('left'),
            }">
            <div v-if="data.widget_position.includes('right')"><i :style="{'color':data.texts}" class="material-icons">arrow_left</i></div>
            <div v-if="data.widget_position.includes('left')"><i :style="{'color':data.texts}" class="material-icons">arrow_right</i></div>
          </button>
        </div>
      <!--action buttons-->
      <div
        v-if="data.widget_position === 'actionButton' || data.widget_position === 'actionButtonB'"
        :class="{
          'binds-action-content' : data.widget_position === 'actionButton',
          'binds-action-contentB' : data.widget_position === 'actionButtonB'
        }"
        >
        <div class="close-binds-action" v-if="closeActionButton && !bindsOpenWidget">
          <app-button inline v-on:click="actionButtonClosed()" :widget="true"> <span style="font-size:20px">x</span> </app-button>
        </div>
        <button
          :class="{
          'binds-button-action' : data.widget_position === 'actionButton',
          'binds-button-actionB' : data.widget_position === 'actionButtonB'
        }"
          v-if="data.widget_position === 'actionButton' || data.widget_position === 'actionButtonB'"
          floating
          v-on:click="bindsOpenWidget = !bindsOpenWidget"
          variation="primary"
          :style="{
            'box-shadow': 'inset 0 0 0 2px' + actionOptions.background,
            'background-color': !actionOptions.iconType ? data.buttons : actionOptions.background
          }"
        >
          <app-icon v-if="actionOptions.iconType === 'designSystem'" :name="actionOptions.icon" size="xs" color="white" style="display:block;font-size: 25px;"/>
          <span v-if="actionOptions.iconType === 'externalUrl'" class="binds-custom-action-icon" :style="{'background-image': 'url('+actionOptions.iconName+')'}"></span>
          <app-icon v-if="!actionOptions.iconType" name="emoji-smiley" size="xs" color="white" style="display:block;font-size: 25px;"/>
        </button>
      </div>
      <!--END action buttons-->
      <Transition name="widget" mode="out-in">
        <div
          v-on:questionUpdated="questionUpdated($event.target.value)"
          v-if="bindsOpenWidget"
          :style="{'background-color': data.background}"
          style="width:100%; height:100%;"
          :class="{
              'binds-animated': bindsOpenWidget,
              'bindsFadeInLeft' : data.widget_position.includes('left') || data.widget_position === 'lowerLeft',
              'bindsFadeInRight' : data.widget_position.includes('right') || data.widget_position === 'lowerRight',
          }"
          >
          <div class="binds-prev-question" v-if="storedQuestions.length > 1 && !data.endMessage">
            <svg
              @click="prevQuesiton()"
              :style="{ 'fill': data.text_buttons }"
              style="width: 30px; height: 30px;vertical-align: middle;overflow: hidden;"
              viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M435.8 512L690 258c18.8-18.8 18.8-49.2 0-67.8-18.8-18.8-49.2-18.6-68 0L334 478c-18.2 18.2-18.6 47.4-1.4 66.2L621.8 834c9.4 9.4 21.8 14 34 14s24.6-4.6 34-14c18.8-18.8 18.8-49.2 0-67.8L435.8 512z"
                />
            </svg>
          </div>
          <app-button
            v-if="data.close_button"
            :widget="true"
            size='small'
            class="binds-btn-close-widget"
            :style="{'padding': '1px 16px 1px', 'background-color' : data.buttons, 'color': data.text_buttons}"
            v-on:click="validateClickAndRules()"
          >X</app-button>
          <div class="binds-center-widget">
            <div class="binds-header-widget" v-if="!data.endMessage" :style="{'margin': data.height === '' || data.height > 100 ? '45px 0 20px' : `${data.height/2}px 0`}">
                <h6 v-html="replaceMeta(data.question.label)" class="binds-heading" style="line-height:1.2; margin-bottom:5px;" :style="{'color': data.texts}">
                </h6>
                <h6 v-html="replaceMeta(data.question.question)" class="binds-heading" style="line-height:1.3;" :style="{'color': data.texts}">
                </h6>
            </div>

            <div
              :class="{'binds-end-message': data.endMessage}"
              class="binds-answers-type"
              :style="{'background': data.endMessage ? data.background : data.response_background, 'max-height': data.endMessage && 'fit-content'}">
              <div class="binds-loading-widget" v-if="loadingWidget">
              <div class="binds-spinner">
                  <div class="binds-ball-1" :style="{'background-color':data.buttons}"></div>
                  <div class="binds-ball-2" :style="{'background-color':data.buttons}"></div>
                </div>
              </div>
                <Transition mode="out-in">
                  <!-- declare all response components -->
                  <Csat v-if="data.question.type === 'csat' && data.question" :data="data" :key="componentKey"/>
                  <Matrix v-if="data.question.type === 'matrix' && data.question.ui !== 'like' && data.question.subjects" :data="data" :key="componentKey"/>
                  <Enum v-if="data.question.type === 'enum' && data.question" :data="data" :key="componentKey"/>
                  <Nes v-if="data.question.type === 'nes'&& data.question" :data="data" :key="componentKey"/>
                  <Ces1to7Button v-if="data.question.type === 'ces' && data.question.ui === '1to7button' && data.question" :data="data" :key="componentKey"/>
                  <Ces1to7 v-if="data.question.type === 'ces' && data.question.ui === '1to7' && data.question" :data="data" :key="componentKey"/>
                  <Nps v-if="data.question.type === 'nps' && data.question" :data="data" :key="componentKey"/>
                  <Kpi v-if="data.question.type === 'kpi' && data.question" :data="data" :key="componentKey"/>
                  <TextInput v-if="data.question.type === 'text' && data.question" :data="data" :key="componentKey"/>
                  <EndMessage v-if="data.endMessage" :data="data" :key="componentKey"/>
                  <Referral v-if="data.question.type === 'referral' && data.question" :data="data" :key="componentKey"/>
                  <Nvs v-if="data.question.type === 'nvs' && data.question" :data="data" :key="componentKey"/>
                  <Matrixlike v-if="data.question.type === 'matrix' && data.question.ui === 'like' && data.question.subjects" :data="data" :key="componentKey"/>
                </Transition>
            </div>
          </div>
        </div>
      </Transition>
      </div>
    <app-button
      class="binds-button-action-when-popup"
      :widget="true"
      v-if=" data.widget_position === 'popup' && !bindsOpenWidget && data.float_button"
      floating
      v-on:click="bindsOpenWidget = !bindsOpenWidget"
      variation="primary"
      :style="{'background-color' : data.buttons}"
    >
    <app-icon name="emoji-smiley" size="xs" color="white" style="display:block;font-size: 25px;"/>
    </app-button>
  </div>
</template>

<script>
import Vue from 'vue'
import Csat from './responseUi/Csat'
import Enum from './responseUi/Enum'
import Matrix from './responseUi/Matrix'
import Nes from './responseUi/Nes'
import Ces1to7 from './responseUi/1to7'
import Ces1to7Button from './responseUi/1to7button'
import Nps from './responseUi/Nps'
import Kpi from './responseUi/Kpi'
import Nvs from './responseUi/Nvs'
import TextInput from './responseUi/TextInput'
import EndMessage from './responseUi/EndMessage'
import Referral from './responseUi/Referral'
import Matrixlike from './responseUi/Matrixlike'
import './bindsMaterial/theme/prebuilt/default.scss'

import { BindsProgress } from './bindsMaterial/components'
import { widgetService } from '@/_services'
import { responsesHelper, loadFonts } from '@/_helpers'
import { replaceMetadata } from '../_helpers/metadata'

Vue.use(BindsProgress)

export default {
  props: [
    'widget_position', 'widget_title', 'width', 'height', 'background',
    'buttons', 'texts', 'timeout', 'width_metric', 'close_button',
    'survey_id', 'metadata', 'text_buttons', 'is_to_post', 'is_preview',
    'from', 'hidde_after_answer', 'hidde_when_click_to_close', 'close_permanently', 'action_config',
    'response_background', 'font_family', 'font_size', 'response_font_color', 'text_props', 'border_radius', 'id', 'font_family_dynamic'
  ],
  components: {
    Csat, Enum, Matrix, Nes, Nps, Kpi, TextInput, EndMessage, Referral, Ces1to7, Ces1to7Button, Nvs, Matrixlike
  },
  head: {
    link: [
      { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=Baskervville:ital@0;1&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Libre+Baskerville:wght@700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap' },
      { rel: 'stylesheet', href: 'https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp|Material+Symbols+Outlined&display=block' }
    ]
  },
  data () {
    return {
      isToShow: true,
      bindsOpenWidget: true,
      loadingWidget: false,
      componentKey: 0,
      actionOptions: {},
      closeActionButton: '',
      arrowToOpen: false,
      data: {
        account: {},
        survey: {},
        ...this.$props,
        ...this.$attrs,
        endMessage: false,
        question: {
          title: 'First question',
          label: 'first question label'
        }
      }
    }
  },
  computed: {
    answersList () { return this.$store.getters['answerList/getAnswersList'] },
    contentPermaClosed () { return sessionStorage.getItem('bindsWidget') },
    storedQuestions () { return this.$store.getters['questions/getQuestion'] },
    parsedTextProps () {
      let parsedObject
      try {
        const parsedProps = JSON.parse(this.data.text_props)

        parsedObject = {
          font_weight: parsedProps.bold ? 'bold' : 'normal',
          text_decoration: parsedProps.underline ? 'underline' : 'normal',
          font_style: parsedProps.italic ? 'italic' : 'normal'
        }
      } catch {
        console.warn('Error parsing text props')
      }

      return parsedObject
    }
  },
  created () {
    this.setProperty()

    // update question object, check if has more question or end message
    this.$root.$on('questionUpdated', (response, rating) => {
      // updating question store
      if (rating !== 'anyValidAnswer') {
        this.pushQuestionToStore(response.nextQuestion)
      }

      // add answer in a list to print on title
      if (['single', 'singleOther', 'multiple', 'multipleOther'].indexOf(this.data.question.ui) > -1) {
        this.insertAnswer(rating)
      } else if (rating === '') {
        this.insertAnswer('')
      } else if (rating !== undefined) {
        const questionOptions = responsesHelper.getOptions(this.data.question)
        const labelSelected = questionOptions.find(option => {
          let answer = rating.toString()
          if (answer) {
            if ((option.value || option.rating || option.label) === answer) {
              return option
            }
          } else {
            return {
              label: ''
            }
          }
        })
        this.insertAnswer(labelSelected.label || '')
      } else {
        this.insertAnswer('')
      }
      // end
      if (response.account) {
        this.data.account = response.account
      }

      if (response.survey) {
        this.data.survey = response.survey
      }

      if (response.nextQuestion && response.nextQuestion.type) {
        this.data.widgetType = response.nextQuestion.type
        this.data.question = response.nextQuestion
        this.data.endMessage = false
      }

      if (response.endMessage) {
        this.data.question.type = undefined
        this.data.question.ui = undefined
        this.data.endMessage = response.endMessage
        if (!this.data.endMessage.logo.link) {
          this.data.endMessage.logo.link = (this.data.survey.images[0].value) ? this.data.survey.images[0].value : response.account.logoUrl
        }
      }

      if (response.sending) {
        this.data.sending = response.sending._id
      }
      // force update on component, to reset the variables when the next question is a the same type of the last
      this.componentKey += 1

      // after question updated check the language of then
      this.replaceLanguage()
      this.$root.$emit('setLoading', false)
    })
    // fecha o widget após responder
    this.$root.$on('hiddeAfterAnswer', () => {
      this.isToShow = false
    })
    // set loading to view of the current user
    this.$root.$on('setLoading', (value) => {
      this.loadingWidget = value
    })
  },
  // get first question to the widget and site timeout to load then
  async mounted () {
    // configuraçoes do modo 'action'
    if (this.action_config) {
      this.actionOptions = JSON.parse(this.action_config)
      if (this.actionOptions.startOpen) { this.bindsOpenWidget = (this.actionOptions.startOpen === 'true') }
      if (this.actionOptions.closeActionButton) { this.closeActionButton = (this.actionOptions.closeActionButton === 'true') }
    } else {
      this.actionOptions.startOpen = true
    }
    // END configuraçoes do modo 'action'
    this.isToShow = false
    let firstQuestion = ''
    this.data.timeout = this.data.timeout * 1000
    setTimeout(async () => {
      if (!this.data.sending) {
        firstQuestion = await widgetService.getFirstQuestion(this.data.survey_id, { 'metadata': JSON.parse(this.data.metadata), 'from': JSON.parse(this.data.from) })
        this.data.question = firstQuestion
        this.pushQuestionToStore(firstQuestion)
      }
      this.replaceLanguage()
      this.isToShow = true
    }, this.data.timeout)
  },
  methods: {
    setProperty () {
      const defaultFontFamily = "Roboto, 'Noto Sans', -apple-system, BlinkMacSystemFont, sans-serif"
      document.documentElement.style.setProperty('--binds_border_radius', `${!isNaN(this.data.border_radius) ? this.data.border_radius : 16}px`)
      document.documentElement.style.setProperty('--binds_font_family', `${this.data.font_family === '' ? defaultFontFamily : this.data.font_family}`)
      document.documentElement.style.setProperty('--binds_font_size', `${this.fontSizeParser(this.font_size)}`)

      if (this.parsedTextProps) {
        document.documentElement.style.setProperty('--binds_font_weight', `${this.parsedTextProps.font_weight}`)
        document.documentElement.style.setProperty('--binds_text_decoration', `${this.parsedTextProps.text_decoration}`)
        document.documentElement.style.setProperty('--binds_font_style', `${this.parsedTextProps.font_style}`)
      }

      if (this.font_family_dynamic) {
        return loadFonts.loadGoogleFont(this.font_family_dynamic)
      }
    },
    flagPermaClose () {
      if (this.close_permanently) {
        sessionStorage.setItem('bindsWidget', 'permaClose')
      }
    },
    fontSizeParser (size) {
      const sizeMap = {
        'xx-small': 'xx-small',
        'x-small': 'x-small',
        'small': 'small',
        'medium': 'medium',
        'normal': 'medium',
        'large': 'large',
        'x-large': 'x-large',
        'xx-large': 'xx-large',
        'xxx-large': 'xxx-large'
      }

      return sizeMap[size] || size
    },
    pushQuestionToStore (receivedQuestion) {
      const questions = this.$store.getters['questions/getQuestion']
      questions.push(receivedQuestion)
      this.$store.commit('questions/setQuestion', questions)
    },
    prevQuesiton () {
      const questions = this.storedQuestions
      questions.pop()
      const lastIndex = questions.length - 1
      this.data.widgetType = questions[lastIndex].type
      this.data.question = questions[lastIndex]
      this.componentKey--
    },
    insertAnswer (value) {
      this.$store.commit('answerList/setAnswer', { id: this.data.question.id, value: value })
    },
    validateClickAndRules () {
      this.bindsOpenWidget = !this.bindsOpenWidget
      if (this.widget_position === 'actionButtonB' || this.widget_position === 'actionButton') {
        if (this.close_permanently) {
          this.isToShow = false
        }
      } else {
        if (this.hidde_when_click_to_close === false && this.bindsOpenWidget === false) {
          this.isToShow = false
        } else {
          // setTimeout para balancear o tempo de fechamento da seta com o tempo da tag <Transition>
          setTimeout(() => {
            this.arrowToOpen = true
          }, 500)
        }
      }
      this.flagPermaClose()
    },
    actionButtonClosed () {
      this.isToShow = false
      this.flagPermaClose()
    },
    replaceLanguage () {
      if (this.data.question.language) {
        const locales = { 'pt-br': 'pt-BR', 'en-us': 'en-US', 'es-cl': 'es-CL' }
        this.$i18n.locale = locales[this.data.question.language]
      }
    },
    replaceMeta (str) {
      const metadata = this.seedData && this.seedData.metadata ? this.seedData.metadata : {}
      return replaceMetadata(str, metadata, this)
    }
  }
}
</script>
<style lang="scss">
/* mobile config */
@media screen and (max-width: 700px) {
  .binds-center-widget div.binds-answers-type {
    min-height: 5rem !important;
  }
  .binds-content-widget {
    width: 94% !important;
    min-width: 94% !important;
  }
  .binds-header-widget > h6.binds-heading {
    font-size: 16px;
  }
  button.binds-button-action {
    bottom: -65px;
  }
  button.binds-button-actionB {
    bottom: -65px;
  }
  div.binds-action{
    bottom: 70px;
  }
  div.binds-actionB{
    bottom: 70px;
  }
}

@media screen and (max-width: 360px) {
  .binds-header-widget > h6.binds-heading {
    font-size: 14px;
  }

  .binds-center-widget div.binds-answers-type {
    max-height: 23rem;
  }
  div.binds-answers-type.binds-end-message {
    max-height: auto !important;
  }
}
.v-enter-active,
.v-leave-active,
.widget-enter-active,
.widget-leave-active {
  transition: opacity 200ms ease-in-out;
}
.v-enter-from,
.v-leave-to,
.widget-enter-from,
.widget-leave-to {
  opacity: 0;
}
.binds-custom-action-icon {
    width: 100%;
    height: 100%;
    background-position: 50%;
    background-size: cover;
    display: block;
    margin: 0 auto;
}
.binds-prev-question {
  padding: 10px 0 0 12px;
  position: absolute;
  cursor: pointer;
}
.bind-space-between-buttons {
  margin: 14px auto !important;
}
.binds-center-widget {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.5);
  border-radius: var(--binds_border_radius);
}
.binds-loading-widget {
  position: absolute;
  top:0;
  width: 100%; /* hack pra poder sobrepor o padding de todos os componentes */
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: var(--binds_border_radius);
}
.binds-content-widget {
  // min-height : 150px;
  // min-width: 416px;
  width:auto;
  height: auto;
  -webkit-transition: width .4s; /* Safari prior 6.1 */
  transition: width .4s;
  z-index: 2147483647; /** max z-index of current browsers */

}
.binds-shadow-popup{
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  z-index: 2147483646;
  left: 0;
  top: 0;
}
.bindsOpenWidget {
  border:none;
  cursor: pointer;
  display: flex;
  padding: 0;
  font-weight: bold;
  overflow: hidden;
  display: flex;
}
.bindsOpenWidget i {
  font-size: 45px;
}
.binds-widget-label-block {
 width: 110px !important;
}
.binds-button-right {
  float: right;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.binds-button-left {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  justify-content: flex-end;
}

.binds-button-left:hover,
.binds-button-right:hover {
  width: 80px;
}
.binds-button-left,
.binds-button-right{
  -webkit-transition: width .4s; /* Safari prior 6.1 */
  transition: width .4s;
    width: 40px;
}
.binds-button-lower{
  height: 30px;
  width: 50px;
  text-align: center;
  transform: translate(50%, 0%);
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: height.4s; /* Safari prior 6.1 */
  transition: height .4s;
}
.binds-button-lower:hover{
  height: 60px;
}
.binds-button-lower > i{
  display: block;
  margin: 0 auto;
}

.binds-top-left {
  top: 3%;
  left: 10px;
}

.binds-middle-left {
  top: 30%;
  left: 10px;
}

.binds-bottom-left {
  bottom: 3%;
  left: 10px;
}

.binds-left {
  top: 8%;
  left: 10px;
}

.binds-action{
  bottom: 96px;
  right: 10px;
}
.binds-actionB{
  bottom: 96px;
  left: 10px;
}

.binds-action-content .close-binds-action,
.binds-action-contentB .close-binds-action {
  position: absolute;
  bottom: -5px;
  right: 0px;
  width: 24px;
  display: flex;
  justify-content: flex-end;
}
.binds-action-contentB .close-binds-action {
  position: absolute;
  bottom: -15px;
  left: 72px;
  width: 24px;
  display: flex;
  justify-content: flex-end;
}

.binds-action-content .close-binds-action .medium,
.binds-action-contentB .close-binds-action .medium{
  padding: 0;
}
.binds-button-action{
  position: absolute;
  bottom: -54px;
  right: 19px;
  margin: 0;
  padding: 0;
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}
.binds-button-actionB{
  position: absolute;
  bottom: -62px;
  left: 19px;
  margin: 0;
  padding: 0;
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}
.binds-button-action-when-popup{
  position: absolute;
  -webkit-box-shadow: 0 5px 10px 0 rgba(255, 96, 125, 0.26) !important;
  box-shadow: 0 15px 20px 0 rgba(48,48,48,.16) !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  right: 12px;
  bottom: 12px;
}
.binds-left .arrow-down {
  transform: rotate(90deg);
}

.binds-top-right {
  top: 3%;
  right: 10px;
}

.binds-middle-right {
  top: 30%;
  right: 10px;
}

.binds-bottom-right {
  bottom: 3%;
  right: 10px;
}

.binds-right {
  top: 8%;
  right: 10px;
}

.binds-header-widget {
  margin: 50px auto 20px;
  background: inherit;
  max-height: 150px;
  overflow-x: auto;
  /* width */
  &::-webkit-scrollbar {
    width: 3px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  h6.binds-heading {
    margin: 0;
    font-size: var(--binds_font_size);
    font-family: var(--binds_font_family);
    font-style: var(--binds_font_style);
    text-decoration: var(--binds_text_decoration);
    font-weight: var(--binds_font_weight);
  }
}

.bindsFadeInRight .binds-header-widget {
  border-top-left-radius: 8px;
}
.bindsFadeInLeft .binds-header-widget {
  border-top-right-radius: 8px;
}
.binds-right .arrow-down{
    transform: rotate(-90deg);
}
.close-widget{
  font-size: 15px !important;
  margin-top: 5px;
  margin-right: 5px;
}
.binds-popup{
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.binds-lower{
  bottom: 6px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.hidden-button{
  visibility: hidden;
}
.binds-btn-close-widget {
  -webkit-box-shadow: 0 5px 10px 0 rgba(255, 96, 125, 0.26) !important;
  box-shadow: 0 15px 20px 0 rgba(48,48,48,.16) !important;
  position: absolute;
  right: 0;
  top: 10px;
  &.binds-ds-button {
    border-radius: var(--binds_border_radius);
  }
}

.binds-animated {
  border-radius: var(--binds_border_radius);
  -webkit-animation-duration: .4s;
  animation-duration: .4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  clear: both;
}
.binds-answers-type{
  width: 100%;
  height: 82%;
  min-height: 15rem;
  padding-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.binds-end-message {
    border-radius: var(--binds_border_radius);
  }
  border-radius: 0 0 var(--binds_border_radius) var(--binds_border_radius);
}
@-webkit-keyframes bindsFadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bindsFadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bindsFadeInRight {
  -webkit-animation-name: bindsFadeInRight;
  animation-name: bindsFadeInRight;
}

@keyframes bindsFadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bindsadeInLeft {
  -webkit-animation-name: bindsFadeInLeft;
  animation-name: bindsFadeInLeft;
}
#bindsApp {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--binds_font_family);
}

 .binds-spinner {
    height: 40px;
    width: 40px;
    position: relative;
    margin: 0 auto;
    margin-top: 25px;
  }
  .binds-ball-1,
  .binds-ball-2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    animation: bindsbounce 2s infinite ease-in-out;
  }
  .binds-ball-2 {
    animation-delay: -1s;
  }
  @keyframes bindsbounce{
      0%, 100%{
        transform: scale(0)
      }
      50%{
          transform: scale(1)
      }
  }
.submit-options-matrix {
  text-align: right;
}
</style>
