<template>
  <div class="container-like-w" id="binds-mobile-matrixlike">
    <div class="content-like">
      <div class="section-matrix-like" v-for="(item, idx) in data.question.subjects" :key="idx">
        <div class="section-label">
          <span :style="{ 'color': data.response_font_color || data.texts}" v-html="replaceMeta(item.label)"></span>
        </div>
        <div class="section-rating">
          <div class="content-rating">
            <app-form-rate-active
              v-model="inputValue"
              :items="options"
              item-value="value"
              @rate-value="updateValue($event,item._id)"
            />
            <i class="material-icons up">thumb_up</i>
            <i class="material-icons down">thumb_down</i>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!data.is_preview" class="content-footer" >
      <div class="footer-answer-component"></div>
        <app-button
          v-if="buttonDisabled"
          :widget="true"
          outline style="
            background-color: none;
            box-shadow: none;
          "
          :style="{'color': data.buttons }"
          v-on:click="postResponse()"
        >
          {{ data.question.nextLabel }}
        </app-button>
      </div>
    </div>
</template>

<script>
import { widgetService } from '@/_services'
import { replaceMetadata, formatObject } from '../../_helpers'

export default {
  props: ['data'],
  components: {
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-form-rate-active': () => import('@binds-tech/binds-design-system/src/components/Form/RateActive.vue')
  },
  data () {
    return {
      inputValue: null,
      value: [],
      buttonDisabled: !this.data.question.required,
      options: {}
    }
  },
  mounted () {
    let options = []
    if (this.data.question.options.length > 0) {
      this.data.question.options.map(op => {
        options.push({ value: op.rating, label: op.label })
      })
      this.options = options
    }
  },
  methods: {
    replaceMeta (str) {
      return replaceMetadata(str, formatObject({ ...JSON.parse(this.data.from), ...JSON.parse(this.data.metadata) }), this)
    },
    updateValue (e, id) {
      if (this.value.length > 0) {
        const indexOption = this.value.findIndex(function (item) {
          return item._id === id
        })
        if (indexOption > -1) {
          this.value[indexOption] = { _id: id, rating: e }
        } else {
          this.value.push({ _id: id, rating: e })
        }
      } else {
        this.value.push({ _id: id, rating: e })
      }
    },
    async postResponse () {
      if (this.data.is_to_post) {
        this.$root.$emit('setLoading', true)
        let dataToSend = ''
        this.value.length > 0 ? dataToSend = { 'value': this.value } : dataToSend = { skip: true, 'sending': this.data.sending }
        let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, dataToSend)
        this.$root.$emit('questionUpdated', questionUpdated)
      }
    }
  },
  watch: {
    value (val) {
      if (this.data.question.required) {
        if (val.length === this.data.question.subjects.length) {
          this.buttonDisabled = true
        } else {
          this.buttonDisabled = false
        }
      }
    }
  }
}
</script>

<style lang="scss">
@media screen and (max-width: 360px), (max-height: 800px) {
  #binds-mobile-matrixlike {
    // max-height: 200px;
    font-size: 13px;
    .material-icons.up {
      right: 10px;
    }
    .material-icons.down {
      left: 10px;
    }
    &.container-like-w .content-like .section-matrix-like {
      width: 96%;
    }
    .content-footer .button.medium {
      padding: 0;
    }
  }
}
@media screen and (max-width: 430px), (max-height: 900px) {
  #binds-mobile-matrixlike {
    // overflow-y: scroll;
    // .rating .rating__item .rating__button {
    //   width: 101% !important;
    //   height: 101% !important;
    // }
    .content-like {
      min-height: 320px;
      max-height: 320px;
    }
  }
}

.container-like-w {
  width: 95%;
  min-height: 250px;
  max-height: 420px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  .content-like {
    overflow-y: auto;
    width: 100%;
    min-height: 100%;
    max-height: 500px;
    margin: 10px 0;
    /* width */
    &::-webkit-scrollbar {
      width: 3px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .section-matrix-like {
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      padding: 4px;
      width: 97%;
      min-height: 50px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .section-label {
        width: 65%;
        min-height: 100%;
        padding-left: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .section-rating {
        width: 35%;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .content-rating {
          position: relative;
          height: 80%;
          width: 90%;
          border-radius: 35px;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.26);
          border: 1px solid #cacaca;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          .rating {
            z-index: 10;
            width: 100%;
            height: 100%;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .rating__item:nth-child(2) {
              width: 50%;
              height: 100%;
              .rating__button {
                width: 100%;
                border-radius: 0;
                height: 100%;
                background: transparent;
              }
              .rating__button:hover {
                background: rgba(0, 0, 0, 0.45) !important;
              }
              .active-rate {
                transform: scale(1);
                border: none;
                background: rgba(0, 0, 0, 0.26) !important;
              }
            }
            .rating__item:nth-child(5) {
              width: 50%;
              height: 100%;
              .rating__button {
                border-left: 1px solid #b6b6b6;
                width: 100%;
                border-radius: 0;
                height: 100%;
                background: transparent;
              }
              .rating__button:hover {
                background: rgba(0, 0, 0, 0.45) !important;
              }
              .active-rate {
                transform: scale(1);
                border: none;
                background: rgba(0, 0, 0, 0.26) !important;
              }
            }
          }
          .up {
            position: absolute;
            right: 20px;
            color: #51ad59;
          }
          .down {
            position: absolute;
            left: 20px;
            color: #ea4922;
          }
        }
      }
    }
  }
  .content-footer {
    text-align: center;
    padding: 0 10px;
  }
}
</style>
