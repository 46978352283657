<template>
  <div class="binds-file">
    <binds-file-icon class="binds-file-icon" :class="iconClass" @click.native="openPicker" />

    <input
      class="binds-input"
      readonly
      v-model="model"
      v-bind="{ disabled, required, placeholder }"
      @click="openPicker"
      @blur="onBlur"
    />

    <input type="file" ref="inputFile" v-bind="attributes" v-on="$listeners" @change="onChange" />
  </div>
</template>

<script>
import BindsUuid from '../../../core/utils/BindsUuid'
import BindsFileIcon from '../../../core/icons/BindsFileIcon'
import BindsFieldMixin from '../BindsFieldMixin'

export default {
  name: 'BindsFile',
  components: {
    BindsFileIcon
  },
  props: {
    id: {
      type: String,
      default: () => 'binds-file-' + BindsUuid()
    },
    name: String
  },
  computed: {
    iconClass () {
      return {
        'binds-disabled': this.disabled
      }
    }
  },
  mixins: [BindsFieldMixin],
  inject: ['BindsField'],
  methods: {
    getMultipleName (files) {
      let names = [];

      [...files].forEach(({ name }) => names.push(name))

      return names.join(', ')
    },
    getFileName (files, target) {
      if (!files || files.length === 0) {
        return target.value.split('\\').pop()
      }

      if (files.length > 1) {
        return this.getMultipleName(files)
      }

      if (files.length === 1) {
        return files[0].name
      }

      return null
    },
    openPicker () {
      this.onFocus()
      this.$refs.inputFile.click()
    },
    onChange ($event) {
      this.onFileSelected($event)
    },
    onFileSelected ({ target, dataTransfer }) {
      const files = target.files || dataTransfer.files

      this.model = this.getFileName(files, target)
      this.$emit('binds-change', files || target.value)
    }
  },
  created () {
    this.BindsField.file = true
  },
  beforeDestroy () {
    this.BindsField.file = false
  }
}
</script>

<style lang="scss">
.binds-file {
  display: flex;
  flex: 1;

  input[type="file"] {
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
    clip: rect(0 0 0 0);
    border: 0;
  }

  .binds-file-icon {
    &:not(.binds-disabled) {
      cursor: pointer;
    }

    &.binds-disabled {
      pointer-events: none;
    }
  }
}
</style>
