<template>
  <div>
    <div :class="this.options.length > 6 ? 'content-subject-matrix' : 'content-subject-matrix1to5'" id="binds-mobile-matrix">
      <div v-for="(item, index) in data.question.subjects" v-bind:key="index">
        <div class="subject-matrix">
          <div class="subject-label">
            <span :style="{ 'color': data.response_font_color || data.texts}" v-html="replaceMeta(item.label)"></span>
          </div>
          <div class="subject-rating">
            <div class="col-md-12 pt-2">
              <app-form-rate-active
                v-model="inputValue"
                :items="options"
                item-value="value"
                item-text="label"
                :textColor="data.textButtons"
                :bgColor="data.buttons"
                @rate-value="updateValue($event,item._id)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="submit-options-matrix" style="text-align: center;" v-if="buttonEnabled">
      <app-button
      :widget="true"
      :binds-ripple="false"
      class="binds-raised binds-primary"
      style="box-shadow: none;background: none;"
      :style="{'color': data.buttons}"
      v-on:click="postResponse()">{{data.question.nextLabel}}</app-button>
    </div>
  </div>
</template>

<script>
import { widgetService } from '@/_services'
import { replaceMetadata, formatObject } from '../../_helpers'

export default {
  props: ['data'],
  components: {
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-form-rate-active': () => import('@binds-tech/binds-design-system/src/components/Form/RateActive.vue')
  },
  data () {
    return {
      inputValue: null,
      value: [],
      buttonEnabled: true,
      options: []
    }
  },
  methods: {
    replaceMeta (str) {
      return replaceMetadata(str, formatObject({ ...JSON.parse(this.data.from), ...JSON.parse(this.data.metadata) }), this)
    },
    updateValue (e, id) {
      if (this.value.length > 0) {
        const indexOption = this.value.findIndex(function (item) {
          return item._id === id
        })
        if (indexOption > -1) {
          this.value[indexOption] = { _id: id, rating: e }
        } else {
          this.value.push({ _id: id, rating: e })
        }
      } else {
        this.value.push({ _id: id, rating: e })
      }

      if (this.data.question.required && this.value.legnth === this.data.question.subjects.length) {
        this.buttonEnabled = true
      }
    },
    async postResponse () {
      if (this.data.is_to_post) {
        this.$root.$emit('setLoading', true)
        let dataToSend = ''
        this.value.length > 0 ? dataToSend = { 'value': this.value, 'sending': this.data.sending } : dataToSend = { skip: true, 'sending': this.data.sending }
        let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, dataToSend)
        this.$root.$emit('questionUpdated', questionUpdated)
      }
    }
  },
  mounted () {
    let options = []
    if (this.data.question.options.length > 0) {
      this.data.question.options.map(op => {
        options.push({ value: op.rating, label: op.label })
      })
      this.options = options
    }
    if (this.data.question.required === true) {
      this.buttonEnabled = false
    }
  },
  watch: {
    value (val) {
      if (this.data.question.required) {
        if (val.length === this.data.question.subjects.length) {
          this.buttonEnabled = true
        } else {
          this.buttonEnabled = false
        }
      }
    }
  }
}
</script>

<style lang="scss">
@media screen and (max-width: 361px) {
  #binds-mobile-matrix {
    .subject-rating .rating .rating__button {
      width: 1.4rem !important;
      height: 1.4rem !important;
    }
    &.content-subject-matrix1to5 .rating .rating__button {
      width: 37px !important;
      height: 37px !important;
    }
    &.content-subject-matrix {
      max-height: 180px !important;
    }
    &.content-subject-matrix1to5 {
      max-height: 190px !important;
    }
  }
}

@media screen and (max-width: 430px), (max-height: 900px) {
  #binds-mobile-matrix {
    .rating .rating__button {
      width: 26px !important;
      height: 26px !important;
    }
    &.content-subject-matrix1to5 .rating__button {
      width: 40px !important;
      height: 40px !important;
    }
    &.content-subject-matrix {
      max-height: 260px;
    }
    &.content-subject-matrix1to5 {
      max-height: 260px;
    }
  }
}
.content-subject-matrix{
  height: auto;
  overflow: auto;
}
.content-subject-matrix1to5, .content-subject-matrix {
  margin-top: 15px;
  max-height: 330px;
  overflow-y: scroll;
  padding: 10px 20px;
}
.subject-matrix{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  .subject-rating{
    width: 100%;
    text-align: center;
    margin-top: 15px;
    .active-rate {
      border: none;
    }
    .rating {
      height: auto;
      padding-bottom: 25px;
    }
    .rating__button{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 29px;
      height: 29px;
      margin-right: 3px;
      font-size: 1rem;
    }
  }
  .subject-label{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.9rem;
  }
}
.content-subject-matrix1to5 {
  margin: 0 auto;
  margin-top: 20px;
  margin-left: 20px;
  &::-webkit-scrollbar {
    width: 3px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .subject-matrix{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    .subject-rating{
      width: 100%;
      .active-rate {
        border: none;
      }
      .rating {
        height: auto;
        padding-bottom: 25px;
      }
      .rating__button{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin-right: 9px;
        font-size: 1rem;
      }
    }
    .subject-label{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 0.9rem;
    }
  }
}
.submit-options-matrix button {
  font-family: var(--binds_font_family);
}
</style>
