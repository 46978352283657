<script>
import Vue from 'vue'
import BindsAppSideDrawer from './BindsAppSideDrawer'
import BindsAppInternalDrawer from './BindsAppInternalDrawer'
import BindsDrawerRightPrevious from '../BindsDrawer/BindsDrawerRightPrevious'

const componentTypes = [
  'binds-app-toolbar',
  'binds-app-drawer',
  'binds-app-content'
]

function normilizeTagName (tagName) {
  return tagName.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}

function isValidChild (componentOptions) {
  return componentOptions && componentTypes.includes(normilizeTagName(componentOptions.tag))
}

function isRightDrawer (propsData) {
  if (!propsData) return false
  return propsData.bindsRight === '' || !!propsData.bindsRight
}

function shouldRenderSlot (data, componentOptions) {
  return (data && componentTypes.includes(data.slot)) || isValidChild(componentOptions)
}

function generateAttrKeys (attrs) {
  return JSON.stringify({
    'persistent': attrs && attrs['binds-persistent'],
    'permanent': attrs && attrs['binds-permanent']
  })
}

/* eslint-disable complexity */
function buildSlots (children, context, functionalContext, options, createElement) {
  let slots = []

  let hasDrawer = false

  if (children) {
    children.forEach(child => {
      const data = child.data
      const componentOptions = child.componentOptions

      if (shouldRenderSlot(data, componentOptions)) {
        const slotName = data.slot || normilizeTagName(componentOptions.tag)
        child.data.slot = slotName

        if (slotName === 'binds-app-drawer') {
          const isRight = isRightDrawer(componentOptions.propsData)

          if (hasDrawer) {
            Vue.util.warn(`There shouldn't be more than one drawer in a BindsApp at one time.`)
            return
          }

          hasDrawer = true
          child.data.slot += `-${isRight ? 'right' : 'left'}`
          child.key = generateAttrKeys(data.attrs)

          if (isRight) {
            const drawerRightPrevious = createElement(BindsDrawerRightPrevious, { props: { ...child.data.attrs } })
            drawerRightPrevious.data.slot = 'binds-app-drawer-right-previous'
            slots.push(drawerRightPrevious)
          }
        }

        child.data.provide = options.Ctor.options.provide
        child.context = context
        child.functionalContext = functionalContext

        slots.push(child)
      }
    })
  }

  return slots
}
/* eslint-enable complexity */

function getDrawers (children) {
  const drawerVnodes = children.filter(child => {
    const tag = child.data.slot || normilizeTagName(child.componentOptions.tag)
    return tag === 'binds-app-drawer'
  })
  return drawerVnodes.length ? drawerVnodes : []
}

function hasInternalDrawer (attrs) {
  const bindsPermanent = attrs && attrs['binds-permanent']

  return bindsPermanent && (bindsPermanent === 'clipped' || bindsPermanent === 'card')
}

export default {
  name: 'BindsApp',
  functional: true,
  render (createElement, { children, props, data }) {
    let appComponent = BindsAppSideDrawer
    const { context, functionalContext, componentOptions } = createElement(appComponent)
    const slots = buildSlots(children, context, functionalContext, componentOptions, createElement)
    const drawers = getDrawers(slots)

    drawers.forEach(drawer => {
      if (drawer && hasInternalDrawer(drawer.data.attrs)) {
        appComponent = BindsAppInternalDrawer
      }
    })

    const staticClass = {}
    if (data.staticClass) {
      data.staticClass.split(/\s+/).forEach(name => {
        if (name.length === 0) return
        staticClass[name] = true
      })
    }

    return createElement(appComponent, {
      attrs: props,
      class: { ...staticClass, ...data.class },
      style: { ...data.staticStyle, ...data.style }
    }, slots)
  }
}
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";
  @import "../BindsLayout/mixins";

  .binds-app {
    display: flex;
    overflow: hidden;
    position: relative;

    &.binds-fixed {
      .binds-app-scroller {
        overflow: auto;
      }
    }

    &.binds-reveal,
    &.binds-fixed-last,
    &.binds-overlap,
    &.binds-flexible {
      transform: translate3d(0, 0, 0);

      .binds-app-toolbar {
        position: absolute;
        top: 0;
      }
    }

    &.binds-flexible,
    &.binds-overlap {
      .binds-app-toolbar {
        min-height: 0;
      }
    }

    &.binds-flexible {
      .binds-toolbar-row {
        &:first-child {
          z-index: 2;
        }

        &:last-child {
          position: fixed;
          bottom: 0;
          z-index: 1;
        }
      }

      .binds-display-1 {
        position: fixed;
      }
    }

    &.binds-overlap {
      .binds-app-toolbar {
        z-index: 1;
      }

      .binds-app-content {
        margin: -64px 24px 24px;
        position: relative;
        z-index: 2;

        @include binds-layout-small {
          margin: -64px 16px 16px;
        }

        @include binds-layout-xsmall {
          margin: -64px 8px 8px;
        }
      }
    }
  }

  .binds-app-drawer {
    &.binds-permanent-card + .binds-app-scroller .binds-content {
      @include binds-layout-small-and-up {
        padding-left: 0;
        padding-right: 0;
        border-left: none;
        border-right: none;
      }
    }
  }

  .binds-app-content {
    padding: 16px;

    @include binds-layout-small-and-up {
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
    }

    > p {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .binds-app-container {
    flex: 1;
    display: flex;
    overflow: auto;
    transform: translate3D(0, 0, 0);
    transition: padding-left .4s $binds-transition-default-timing,
                padding-right .4s $binds-transition-default-timing;
    will-change: padding-left, padding-right;
  }

  .binds-app-scroller {
    flex: 1;
  }
</style>
