<template>
  <div class="binds-enum-parent">
    <num5or10
      :data='data'
      v-if="!~['singleOther', 'single', 'multiple', 'multipleOther'].indexOf(data.question.ui)"
    ></num5or10>

  <div class="container-enum-multiple" v-if="data.question.ui === 'multiple' || data.question.ui === 'multipleOther'">
      <div class="content-enum-multiple">
        <div class="options-enum-multiple">
          <div class="option" :class="`option-multiple-${idx}`" v-for="(option, idx) in data.question.options" :key="idx" @click="checkMultiple(option.label, idx)">
            <i class="material-icons unchecked" :class="`uncheck-${idx}`" ref="unchecked_multiple">check_box_outline_blank</i>
            <i class="material-icons checked" :class="`check-${idx}`" style="display: none" ref="checked_multiple">check_box</i>
            <p class="label-option" v-html="replaceMeta(option.label)"></p>
          </div>
          <div class="option-other" v-if="data.question.ui === 'multipleOther'">
            <div class="content-other" @click="other()">
              <i class="material-icons unchecked-other" v-if="!isOther">check_box_outline_blank</i>
              <i class="material-icons checked-other" v-if="isOther">check_box</i>
              <p class="label-option" v-html="replaceMeta(data.question.otherLabel)"></p>
            </div>
            <div class="option-text" v-if="isOther">
              <input class="input-other" type="text" v-model="otherModel" :placeholder="data.question.placeholder" @keyup="textValue()">
              <p class="message-length-other" v-if="data.question.required && otherModel.length <= 0">{{ $t('other_required') }}</p>
            </div>
          </div>
          <div class="max-choice" v-if="data.question.limitAnswer">
            <p class="limit-choice">{{ $t('multiple_answer_required') }} {{ data.question.minLength }} {{ $t('multiple_and') }} {{ data.question.maxLength }} {{ $t('multiple_options') }}.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-enum-single" v-if="data.question.ui === 'single' || data.question.ui === 'singleOther'">
      <div class="options-enum-single">
        <div class="option" :class="`op-single-${idx}`" v-for="(option, idx) in data.question.options" :key="idx" @click="checkSingle(option.label, idx)">
          <i class="material-icons check" :class="`check-${idx}`" ref="checked_single">radio_button_checked</i>
          <i class="material-icons uncheck" :class="`uncheck-${idx}`" ref="unchecked_single">radio_button_unchecked</i>
          <p class="label-option" v-html="replaceMeta(option.label)"></p>
        </div>
        <div class="option-other" v-if="data.question.ui === 'singleOther'">
          <div class="content-other" @click="other()">
            <i class="material-icons uncheck-other" v-if="!isOther">radio_button_unchecked</i>
            <i class="material-icons check-other" v-if="isOther">radio_button_checked</i>
            <p class="label-option" v-html="replaceMeta(data.question.otherLabel)"></p>
          </div>
          <div class="option-text" v-if="isOther">
            <input class="input-other" type="text" v-model="otherModel" :placeholder="data.question.placeholder" @keyup="textValue()">
          </div>
        </div>
      </div>
    </div>

      <div
        class="submit-options-matrix binds-animated-faster zoomIn"
        v-if="buttonEnabled"
      >
        <app-button
          :widget="true"
          style="box-shadow: none;background: none;"
          :style="{'color': data.buttons}"
          v-on:click="handleSubmit()">
          {{ data.question.nextLabel }}
        </app-button>
      </div>
  </div>
</template>

<script>
import num5or10 from '../num5or10.vue'
import { widgetService } from '@/_services'
import { replaceMetadata, formatObject } from '../../_helpers'

export default {
  props: ['data'],
  components: { num5or10 },
  data () {
    return {
      singleOption: '',
      otherModel: '',
      multiples: [],
      buttonEnabled: true,
      isOther: false,
      message: '',
      totalLimitAnswer: 0,
      isLimit: false
    }
  },
  watch: {
    multiples () {
      this.totalLimitAnswer = this.multiples.length
    }
  },
  methods: {
    replaceMeta (str) {
      return replaceMetadata(str, formatObject({ ...JSON.parse(this.data.from), ...JSON.parse(this.data.metadata) }), this)
    },
    handleSubmit () {
      if ((['single', 'singleOther'].indexOf(this.data.question.ui) >= 0) && this.singleOption !== '') this.postResponse(this.singleOption, this.otherModel)
      else if (['multiple', 'multipleOther'].indexOf(this.data.question.ui) >= 0) this.postResponse(this.multiples, this.otherModel)
      else this.postResponse(this.data.question.otherLabel, this.otherModel)
    },
    async postResponse (item, text) {
      if (this.data.is_to_post) {
        let send = { value: item || '', 'sending': this.data.sending }
        if (this.data.question.ui !== 'multiple') {
          send.text = text
        }
        if (['single', 'singleOther'].indexOf(this.data.question.ui) >= 0) {
          send.isOther = this.isOther
        }
        if (this.isOther && !this.singleOption) {
          send.isOther = true
          if (this.data.question.ui === 'multipleOther') {
            // eslint-disable-next-line
            const itemsCheck = structuredClone(item)
            send.value = itemsCheck
          }
        }
        this.$root.$emit('setLoading', true)
        let dataToSend = ''
        send.value.length > 0 ? dataToSend = send : dataToSend = { skip: true }
        let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, dataToSend)
        this.$root.$emit('questionUpdated', questionUpdated, item)
      }
    },
    getRefs (ref, index, value) {
      this.$refs[`${ref}`][index].style.display = value
    },
    checkSingle (item, index) {
      this.isOther = false
      this.otherModel = ''
      if (this.singleOption === item) {
        this.getRefs('checked_single', index, 'none')
        this.getRefs('unchecked_single', index, 'flex')
        this.singleOption = ''
        this.buttonEnabled = false
      } else {
        this.$refs.checked_single.forEach(option => { option.style.display = 'none' })
        this.$refs.unchecked_single.forEach(option => { option.style.display = 'flex' })
        this.getRefs('checked_single', index, 'flex')
        this.getRefs('unchecked_single', index, 'none')
        this.singleOption = item
        this.buttonEnabled = true
      }
    },
    checkMultiple (item, index) {
      const minRange = this.data.question.minLength
      const maxRange = this.data.question.maxLength
      const hasText = this.otherModel.length > 0
      let passOnMinRule = true
      let passOnMaxRule = true

      if (!this.multiples.find(i => i === item)) {
        if (this.data.question.limitAnswer) {
          if (maxRange !== undefined) {
            if (this.totalLimitAnswer === maxRange && this.isOther) {
              passOnMaxRule = true
              this.isLimit = true
            } else if (this.totalLimitAnswer === maxRange) {
              passOnMaxRule = true
              this.isLimit = true
            } else if (this.totalLimitAnswer > maxRange) {
              passOnMaxRule = true
              this.isLimit = true
            }
          }
          if (this.isLimit) return
        }

        this.getRefs('checked_multiple', index, 'flex')
        this.getRefs('unchecked_multiple', index, 'none')
        this.multiples.push(item)
      } else {
        this.getRefs('checked_multiple', index, 'none')
        this.getRefs('unchecked_multiple', index, 'flex')
        this.multiples = this.multiples.filter(i => i !== item)
        this.isLimit = false
      }

      if (minRange !== undefined && this.multiples.length < minRange) {
        passOnMinRule = false
        this.isLimit = false
      }

      if (passOnMinRule && passOnMaxRule && this.multiples.length > 0 && (this.isOther && hasText)) {
        this.buttonEnabled = true
      } else if (passOnMinRule && passOnMaxRule && this.multiples.length > 0 && !this.isOther) {
        this.buttonEnabled = true
      } else if (this.multiples.length <= 0) {
        this.isLimit = false
        this.buttonEnabled = false
      } else {
        this.buttonEnabled = false
      }
    },
    other () {
      const maxRange = this.data.question.maxLength
      const minRange = this.data.question.minLength

      if (this.data.question.ui === 'singleOther') {
        this.isOther = !this.isOther

        if (this.isOther && this.otherModel === '') this.buttonEnabled = false
        else this.buttonEnabled = true

        this.$refs.checked_single.forEach(option => { option.style.display = 'none' })
        this.$refs.unchecked_single.forEach(option => { option.style.display = 'flex' })
        return
      }

      if (this.totalLimitAnswer === maxRange && this.data.question.limitAnswer) this.isLimit = true

      if (!this.isLimit) this.isOther = !this.isOther
      else if (this.isLimit && this.isOther) this.isOther = false

      if (this.isOther) {
        if (this.isLimit) return
        if (this.otherModel === '') this.buttonEnabled = false
        this.multiples.push(this.data.question.otherLabel)
        if (this.totalLimitAnswer === maxRange && this.data.question.limitAnswer) this.isLimit = true
      } else {
        this.otherModel = ''
        this.multiples = this.multiples.filter(i => i !== this.data.question.otherLabel)
        if (this.totalLimitAnswer === maxRange) this.isLimit = false
        if (this.multiples.length < minRange) this.buttonEnabled = false
        else if (this.multiples.length === minRange && minRange > 0) this.buttonEnabled = true
        else if (this.multiples.length > 0 && !this.data.question.limitAnswer) this.buttonEnabled = true
      }

      if (!this.isOther && this.multiples.length <= 0) this.buttonEnabled = false
      else if (this.isOther && this.otherModel !== '') this.buttonEnabled = true
    },
    textValue () {
      switch (this.data.question.required) {
        case ((this.multiples.length > 0 && this.otherModel.length > 0) || (this.multiples.length <= 0 && this.otherModel.length > 0) || (this.multiples.length > 0 && this.otherModel.length <= 0)) && this.otherModel !== '':
          if (this.data.question.limitAnswer && (this.data.question.maxLength === this.data.question.minLength) && (this.multiples.length < this.data.question.maxLength)) {
            this.buttonEnabled = false
          } else {
            this.buttonEnabled = true
          }
          break
        case (this.multiples.length === 1 && this.otherModel.length <= 0) || (this.multiples.length > 0 && this.otherModel.length <= 0):
          this.buttonEnabled = false
          break
        default:
          this.buttonEnabled = false
          break
      }
    }
  },
  created () {
    document.documentElement.style.setProperty('--binds_splashColor', `${this.data.response_font_color || this.data.texts}`)
    document.documentElement.style.setProperty('--binds_buttonBg', `${this.data.buttons}`)

    if (this.data.question.required === true) {
      this.buttonEnabled = false
    }
    this.$root.$emit('setLoading', false)
  }
}
</script>
<style lang="scss">
:root {
  --binds_splashColor: 0;
  --binds_buttonBg: 0;
}

@media screen and (max-width: 430px), (max-height: 900px) {
  .container-enum-single, .container-enum-multiple {
    overflow-y: scroll;
    max-height: 350px;
  }
  .submit-options-matrix > button.binds-ds-button {
    padding: 0;
  }

  .options-enum-multiple {
    overflow-y: none;
  }
}
@media screen and (max-width: 360px), (max-height: 880px) {
.container-enum-single, .container-enum-multiple {
    height: 190px;
  }
  .option, .options-enum-single,
  .options-enum-multiple,
  .option-other, .content-other {
    > p {
      font-size: 13px !important;
      font-family: var(--binds_font_family);
    }
  }
}
div.submit-options-matrix {
  text-align: center;
}
.binds-enum-parent {
  width: 100%;
}
.content-enum-multiple {
  width: 100%;
  min-height: fit-content; // old value: min-height: 370px;
  box-sizing: border-box;
  padding: 2px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .options-enum-multiple {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 350px;
    align-items: center;
    justify-content: unset;
    overflow-x: auto;
    .max-choice {
      width: 95%;
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .limit-choice {
        color: var(--binds_buttonBg);
        font-size: 13px;
      }
    }
    .option, .option-other {
      background: transparent;
      cursor: pointer;
      min-height: auto;
      width: 90%;
      border-radius: 5px;
      margin: 3px;
      padding: 5px;
      border: 1px solid #e0e0e0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        width: 94%;
        font-size: 16px;
        color: var(--binds_splashColor);
        font-family: var(--binds_font_family);
      }
      .unchecked, .checked, .unchecked-other, .checked-other {
        color: var(--binds_splashColor);
        font-size: 20px;
        margin: 0 10px;
      }
    }
    .option:hover {
      transform: scale(1.01);
    }
    .option-other {
      flex-direction: column;
      .content-other {
        min-height: 40px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          width: 94%;
          font-family: var(--binds_font_family);
        }
      }
      .option-text {
        width: 95%;
        height: 50px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        input {
          font-size: 16px;
          width: 95%;
          height: 40px;
          margin-bottom: 5px;
          color: var(--binds_splashColor);
          font-family: var(--binds_font_family);
          border-radius: 5px;
          outline: none;
          padding-left: 5px;
          border: 1px solid #e0e0e0;
        }
        .message-length-other {
          font-size: 12px;
          color: #ff0000d9;
        }
      }
    }
  }
}

  .options-enum-single {
    min-height: fit-content; // old value: min-height: 370px;
    max-height: 360px;
    width: 100%;
    box-sizing: border-box;
    padding: 2px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: unset;
    overflow-x: auto;
    .option, .option-other {
      background: transparent;
      cursor: pointer;
      min-height: auto;
      width: 90%;
      border-radius: 5px;
      margin: 3px;
      padding: 5px;
      border: 1px solid #e0e0e0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        width: 94%;
        font-size: 16px;
        color: var(--binds_splashColor);
        font-family: var(--binds_font_family);
      }
      .uncheck, .check, .uncheck-other, .check-other {
        color: var(--binds_splashColor);
        font-size: 20px;
        margin: 0 10px;
      }
      .uncheck {
        display: flex;
      }
      .check {
        display: none;
      }
    }
    .option:hover {
      transform: scale(1.01);
    }
    .option-other {
      flex-direction: column;
      .content-other {
        min-height: 40px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          width: 94%;
          font-family: var(--binds_font_family);
        }
      }
      .option-text {
        width: 95%;
        height: 30px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        input {
          width: 95%;
          height: 100%;
          color: var(--binds_splashColor);
          font-family: var(--binds_font_family);
          border-radius: 5px;
          outline: none;
          padding-left: 5px;
          border: 1px solid #e0e0e0;
          font-size: 16px;
        }
      }
    }
  }

.label-option {
  color: var(--binds_splashColor);
  font-family: var(--binds_font_family);
}
</style>
