<template>
  <td class="binds-table-cell" :class="cellClasses">
    <div class="binds-table-cell-container">
      <slot />
    </div>
  </td>
</template>

<script>
export default {
  name: 'BindsTableCell',
  props: {
    bindsId: [String, Number],
    bindsLabel: String,
    bindsNumeric: Boolean,
    bindsTooltip: String,
    bindsSortBy: String
  },
  inject: ['BindsTable'],
  data: () => ({
    index: null,
    parentNode: null
  }),
  computed: {
    cellClasses () {
      return {
        'binds-numeric': this.bindsNumeric
      }
    }
  },
  watch: {
    bindsSortBy () {
      this.setCellData()
    },
    bindsNumeric () {
      this.setCellData()
    },
    bindsLabel () {
      this.setCellData()
    },
    bindsTooltip () {
      this.setCellData()
    }
  },
  methods: {
    setCellData ($vm = this) {
      this.$set(this.BindsTable.items, $vm.index, {
        id: $vm.bindsId,
        label: $vm.bindsLabel,
        numeric: $vm.bindsNumeric,
        tooltip: $vm.bindsTooltip,
        sortBy: $vm.bindsSortBy
      })
    },
    updateAllCellData () {
      this.BindsTable.items = {}

      const cells = Array.from(this.parentNode.childNodes).filter(({ tagName, classList }) => {
        const isSelection = classList && classList.contains('binds-table-cell-selection')
        const isTd = tagName && tagName.toLowerCase() === 'td'

        return isTd && !isSelection
      })

      cells.forEach((cell, index) => {
        const $vm = cell.__vue__

        $vm.index = index

        this.setCellData($vm)
      })
    }
  },
  mounted () {
    this.parentNode = this.$el.parentNode
    this.updateAllCellData()
  },
  destroyed () {
    const rowRemoved = this.$el.parentNode !== null

    if (rowRemoved) {
      return false
    }

    this.updateAllCellData()
  }
}
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-table-cell {
    height: 48px;
    position: relative;
    transition: .3s $binds-transition-default-timing;
    font-size: 13px;
    line-height: 18px;

    &.binds-numeric {
      text-align: right;
    }

    &:last-child .binds-table-cell-container {
      padding-right: 24px;
    }
  }

  .binds-table-cell-container {
    padding: 6px 32px 6px 24px;
  }
</style>
