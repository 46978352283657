<template>
  <td class="binds-table-cell binds-table-cell-selection" v-if="bindsSelectable">
    <div class="binds-table-cell-container">
      <binds-checkbox v-model="isSelected" :disabled="!bindsSelectable || bindsDisabled" @change="onChange" />
    </div>
  </td>
</template>

<script>
export default {
  name: 'BindsTableCellSelection',
  props: {
    value: Boolean,
    bindsRowId: [Number, String],
    bindsSelectable: Boolean,
    bindsDisabled: Boolean
  },
  inject: ['BindsTable'],
  data: () => ({
    isSelected: false
  }),
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.isSelected = value
      }
    }
  },
  methods: {
    onChange () {
      this.$emit('input', this.isSelected)
    }
  }
}
</script>

<style lang="scss">
  .binds-table-cell-selection {
    width: 66px;

    + th {
      .binds-table-head-label {
        padding-left: 0;
      }
    }

    + td {
      .binds-table-cell-container {
        padding-left: 0;
      }
    }

    .binds-table-head-container,
    .binds-table-cell-container,
    .binds-table-head-label,
    .binds-table-cell-label {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: visible;
    }

    .binds-checkbox {
      margin: 0;

      .binds-checkbox-container {
        width: 18px;
        min-width: 18px;
        height: 18px;

        &:after {
          top: -1px;
          left: 4px;
        }
      }
    }
  }
</style>
