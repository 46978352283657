<template>
  <binds-menu-item :class="optionClasses" :disabled="isDisabled" @click="setSelection">
    <binds-checkbox
      class="binds-primary"
      v-model="isChecked"
      v-if="BindsSelect.multiple"
      :disabled="isDisabled"
    />

    <span class="binds-list-item-text" ref="text">
      <slot />
    </span>
  </binds-menu-item>
</template>

<script>
import BindsUuid from '../../../core/utils/BindsUuid'

export default {
  name: 'BindsOption',
  props: {
    value: [String, Number, Boolean],
    disabled: Boolean
  },
  inject: {
    BindsSelect: {},
    BindsOptgroup: {
      default: {}
    }
  },
  data: () => ({
    uniqueId: 'binds-option-' + BindsUuid(),
    isSelected: false,
    isChecked: false
  }),
  computed: {
    selectValue () {
      return this.BindsSelect.modelValue
    },
    isMultiple () {
      return this.BindsSelect.multiple
    },
    isDisabled () {
      return this.BindsOptgroup.disabled || this.disabled
    },
    key () {
      let isSet = this.value || this.value === 0
      return isSet ? this.value : this.uniqueId
    },
    inputLabel () {
      return this.BindsSelect.label
    },
    optionClasses () {
      return {
        'binds-selected': this.isSelected || this.isChecked
      }
    }
  },
  watch: {
    selectValue () {
      this.setIsSelected()
    },
    isChecked (val) {
      if (val === this.isSelected) {
        return
      }
      this.setSelection()
    },
    isSelected (val) {
      this.isChecked = val
    }
  },
  methods: {
    getTextContent () {
      if (this.$el) {
        return this.$el.textContent.trim()
      }

      const slot = this.$slots.default

      return slot ? slot[0].text.trim() : ''
    },
    setIsSelected () {
      if (!this.isMultiple) {
        this.isSelected = this.selectValue === this.value
        return
      }
      if (this.selectValue === undefined) {
        this.isSelected = false
        return
      }
      this.isSelected = this.selectValue.includes(this.value)
    },
    setSingleSelection () {
      this.BindsSelect.setValue(this.value)
    },
    setMultipleSelection () {
      this.BindsSelect.setMultipleValue(this.value)
    },
    setSelection () {
      if (!this.isDisabled) {
        if (this.isMultiple) {
          this.setMultipleSelection()
        } else {
          this.setSingleSelection()
        }
      }
    },
    setItem () {
      this.$set(this.BindsSelect.items, this.key, this.getTextContent())
    }
  },
  updated () {
    this.setItem()
  },
  created () {
    this.setItem()
    this.setIsSelected()
  }
}
</script>

<style lang="scss">
</style>
