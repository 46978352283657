<script>
import BindsComponent from '../../core/BindsComponent'

export default new BindsComponent({
  name: 'BindsContent',
  props: {
    bindsTag: {
      type: String,
      default: 'div'
    }
  },
  render (createElement) {
    return createElement(this.bindsTag, {
      staticClass: 'binds-content',
      class: [this.$bindsActiveTheme],
      attrs: this.$attrs,
      on: this.$listeners
    }, this.$slots.default)
  }
})
</script>
