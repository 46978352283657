<template>
  <binds-button
    class="binds-bottom-bar-item"
    :class="itemClasses"
    :id="id"
    :disabled="bindsDisabled"
    :binds-ripple="BindsBottomBar.type === 'fixed'"
    v-bind="attrs"
    v-on="$listeners"
    @click="setActiveItem">
    <slot v-if="$slots.default"></slot>

    <template v-else>
      <binds-icon class="binds-bottom-bar-icon" v-if="isAssetIcon(bindsIcon)" :binds-src="bindsIcon"></binds-icon>
      <binds-icon class="binds-bottom-bar-icon" v-else>{{ bindsIcon }}</binds-icon>
      <span class="binds-bottom-bar-label">{{ bindsLabel }}</span>
    </template>
  </binds-button>
</template>

<script>
import BindsAssetIcon from '../../core/mixins/BindsAssetIcon/BindsAssetIcon'
import BindsRouterLink from '../../core/mixins/BindsRouterLink/BindsRouterLink'
import BindsUuid from '../../core/utils/BindsUuid'
import BindsRouterLinkProps from '../../core/utils/BindsRouterLinkProps'

const ignoredProps = ['id', 'bindsLabel', 'bindsIcon', 'bindsDisabled']

export default {
  name: 'BindsBottomBarItem',
  mixins: [BindsAssetIcon, BindsRouterLink],
  props: {
    id: {
      type: String,
      default: () => 'binds-bottom-bar-item-' + BindsUuid()
    },
    bindsLabel: String,
    bindsIcon: String,
    bindsDisabled: Boolean
  },
  inject: ['BindsBottomBar'],
  watch: {
    $props: {
      deep: true,
      handler () {
        this.setItemData()
      }
    },
    $attrs: {
      deep: true,
      handler () {
        this.setItemData()
      }
    }
  },
  computed: {
    itemClasses () {
      return {
        'binds-active': this.id === this.BindsBottomBar.activeItem
      }
    },
    attrs () {
      let attrs = { ...this.$attrs }

      const propNames = Object.keys(this.$options.propsData)
      propNames.forEach(prop => {
        if (!ignoredProps.includes(prop)) {
          attrs[prop] = this[prop]
        }
      })

      return attrs
    }
  },
  methods: {
    getPropValues () {
      const propNames = Object.keys(this.$options.props)
      let values = {}

      propNames.forEach(prop => {
        if (!ignoredProps.includes(prop)) {
          if (this[prop]) {
            values[prop] = this[prop]
          } else if (this.$attrs && this.$attrs.hasOwnProperty(prop)) {
            if (prop) {
              values[prop] = this.$attrs[prop]
            } else {
              values[prop] = true
            }
          }
        }
      })

      return values
    },
    setItemData () {
      this.$set(this.BindsBottomBar.items, this.id, {
        disabled: this.bindsDisabled,
        options: this.bindsTemplateOptions,
        props: this.getPropValues()
      })
    },
    setActiveItem ($event) {
      if (!this.BindsBottomBar.syncRoute) {
        this.BindsBottomBar.activeItem = this.id
      }

      if (this.BindsBottomBar.type === 'shift') {
        this.BindsBottomBar.mouseEvent = $event
      }
    }
  },
  beforeCreate () {
    if (this.$router && this.$options.propsData.to) {
      const componentProps = BindsRouterLinkProps(this, this.$options.props)
      this.$options.props = componentProps
    }
  },
  created () {
    this.setItemData()
  },
  beforeDestroy () {
    this.$delete(this.BindsBottomBar.items, this.id)
  }
}
</script>
