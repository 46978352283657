<template>
  <binds-card class="binds-app-content binds-flex" v-bind="$attrs" v-on="$listeners" v-if="showCard">
    <slot />
  </binds-card>

  <binds-content class="binds-app-content binds-flex" v-bind="$attrs" v-on="$listeners" v-else>
    <slot />
  </binds-content>
</template>

<script>
export default {
  name: 'BindsAppContent',
  inject: ['BindsApp'],
  computed: {
    showCard () {
      return this.BindsApp.options && this.BindsApp.options.mode === 'overlap'
    }
  }
}
</script>

<style lang="scss">
  .binds-app-content {
    height: 100%;

    .binds-card {
      margin-right: 16px;
      margin-left: 16px;
      overflow: visible;
    }
  }
</style>
