import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import axios from 'axios'  ->> comentado para corrigir erro de load no ESLINT, na versao que funciona não bate nessas funcoes
import messages from '@/translations/i18n/lang/'
let languageInput = 'pt-BR'

if (localStorage.getItem('bindsLang')) {
  languageInput = localStorage.getItem('bindsLang')
}

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: languageInput, // set locale
  fallbackLocale: languageInput,
  messages // set locale messages
})
// const loadedLanguages = [languageInput] // ->> comentado para corrigir erro de load no ESLINT, na versao que funciona não bate nessas funcoes

// function setI18nLanguage (lang) { ->> comentado para corrigir erro de load no ESLINT, na versao que funciona não bate nessas funcoes
//   i18n.locale = lang
//   axios.defaults.headers.common['Accept-Language'] = lang
//   document.querySelector('html').setAttribute('lang', lang)
//   return lang
// }

// export function loadLanguageAsync (lang) { ->> comentado para corrigir erro de load no ESLINT, na versao que funciona não bate nessas funcoes
//   if (i18n.locale !== lang) {
//     if (!loadedLanguages.includes(lang)) {
//       return import(/* webpackChunkName: "lang-[request]" */ `@/translations/i18n/lang/${lang}`).then(
//         msgs => {
//           console.log(msgs)
//           i18n.setLocaleMessage(lang, msgs.default)
//           loadedLanguages.push(lang)
//           return setI18nLanguage(lang)
//         }
//       )
//     }
//     return Promise.resolve(setI18nLanguage(lang))
//   }
//   return Promise.resolve(lang)
// }

export default i18n
