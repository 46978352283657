<template>
  <div class="binds-image" :class="[$bindsActiveTheme]">
    <slot />
  </div>
</template>

<style lang="scss">
  .binds-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>

<script>
import BindsComponent from '../../core/BindsComponent'

export default new BindsComponent({
  name: 'BindsImage',
  props: {
    bindsSrc: String
  }
})
</script>
