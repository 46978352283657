<template>
  <div class="binds-field" :class="[$bindsActiveTheme, fieldClasses]" @blur="onBlur">
    <slot />

    <span
      class="binds-count"
      v-if="hasCounter"
    >{{ valueLength }} / {{ BindsField.maxlength || BindsField.counter }}</span>

    <transition name="binds-input-action" appear>
      <binds-button
        tabindex="-1"
        class="binds-icon-button binds-dense binds-input-action binds-clear"
        @click="clearInput"
        v-if="hasValue && bindsClearable"
        :disabled="BindsField.disabled"
      >
        <binds-clear-icon />
      </binds-button>
    </transition>

    <transition name="binds-input-action" appear>
      <binds-button
        tabindex="-1"
        class="binds-icon-button binds-dense binds-input-action binds-toggle-password"
        @click="togglePassword"
        v-if="hasPasswordToggle"
      >
        <binds-password-on-icon v-if="BindsField.togglePassword" />
        <binds-password-off-icon v-else />
      </binds-button>
    </transition>
  </div>
</template>

<script>
import BindsComponent from '../../core/BindsComponent'
import BindsClearIcon from '../../core/icons/BindsClearIcon'
import BindsPasswordOffIcon from '../../core/icons/BindsPasswordOffIcon'
import BindsPasswordOnIcon from '../../core/icons/BindsPasswordOnIcon'

export default new BindsComponent({
  name: 'BindsField',
  components: {
    BindsClearIcon,
    BindsPasswordOffIcon,
    BindsPasswordOnIcon
  },
  props: {
    bindsInline: Boolean,
    bindsClearable: Boolean,
    bindsCounter: {
      type: Boolean,
      default: true
    },
    bindsTogglePassword: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    showPassword: false,
    BindsField: {
      value: null,
      focused: false,
      highlighted: false,
      disabled: false,
      required: false,
      placeholder: false,
      textarea: false,
      autogrow: false,
      maxlength: null,
      counter: null,
      password: null,
      togglePassword: false,
      clear: false,
      file: false
    }
  }),
  provide () {
    return {
      BindsField: this.BindsField
    }
  },
  computed: {
    stringValue () {
      return (
        (this.BindsField.value || this.BindsField.value === 0) &&
        this.BindsField.value.toString()
      )
    },
    hasCounter () {
      return (
        this.bindsCounter &&
        (this.BindsField.maxlength || this.BindsField.counter)
      )
    },
    hasPasswordToggle () {
      return this.bindsTogglePassword && this.BindsField.password
    },
    hasValue () {
      return this.stringValue && this.stringValue.length > 0
    },
    valueLength () {
      if (this.stringValue) {
        return this.stringValue.length
      }

      return 0
    },
    fieldClasses () {
      return {
        'binds-inline': this.bindsInline,
        'binds-clearable': this.bindsClearable,
        'binds-focused': this.BindsField.focused,
        'binds-highlight': this.BindsField.highlighted,
        'binds-disabled': this.BindsField.disabled,
        'binds-required': this.BindsField.required,
        'binds-has-value': this.hasValue,
        'binds-has-placeholder': this.BindsField.placeholder,
        'binds-has-textarea': this.BindsField.textarea,
        'binds-has-password': this.BindsField.password,
        'binds-has-file': this.BindsField.file,
        'binds-has-select': this.BindsField.select,
        'binds-autogrow': this.BindsField.autogrow
      }
    }
  },
  methods: {
    clearInput () {
      this.BindsField.clear = true
      this.$emit('binds-clear')
      this.$nextTick().then(() => {
        this.BindsField.clear = false
      })
    },
    togglePassword () {
      this.BindsField.togglePassword = !this.BindsField.togglePassword
    },
    onBlur () {
      this.BindsField.highlighted = false
    }
  }
})
</script>

<style lang="scss">
@import "../BindsAnimation/variables";

$binds-input-height: 32px;

.binds-field {
  width: 100%;
  min-height: 48px;
  margin: 4px 0 24px;
  padding-top: 16px;
  display: flex;
  position: relative;
  font-family: inherit;

  &:before,
  &:after {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    transition: border 0.3s $binds-transition-default-timing,
      opacity 0.3s $binds-transition-default-timing,
      transform 0s 0.3s $binds-transition-default-timing;
    will-change: border, opacity, transform;
    content: " ";
  }

  &:after {
    height: 1px;
  }

  &:before {
    height: 2px;
    z-index: 2;
    opacity: 0;
    transform: scaleX(0.12);
  }

  label {
    position: absolute;
    top: 23px;
    left: 0;
    pointer-events: none;
    transition: $binds-transition-stand;
    transition-duration: 0.3s;
    font-size: 16px;
    line-height: 20px;
  }
  .binds-suffix {
    font-size: 16px;
    line-height: 32px;
    align-self: center;
    justify-self: center;
  }

  .binds-prefix {
    display: none;
    padding-right: 4px;
    font-size: 16px;
    line-height: 32px;
    align-self: center;
    justify-self: center;
  }

  &.binds-focused,
  &.binds-has-value {
    .binds-prefix {
      display: block;
    }
  }

  .binds-input,
  .binds-textarea {
    height: $binds-input-height;
    padding: 0;
    display: block;
    flex: 1;
    border: none;
    background: none;
    transition: $binds-transition-stand;
    transition-property: font-size, padding-top, color;
    font-family: inherit;
    font-size: 16px;
    line-height: $binds-input-height;

    &[type="date"] {
      font-size: 16px;
    }

    &[disabled] {
      cursor: default;
    }

    &:focus {
      outline: none;
    }

    &::-webkit-input-placeholder {
      font-size: 16px;
      text-shadow: none;
      -webkit-text-fill-color: initial;
      transition: $binds-transition-stand;
      transition-property: font-size, color;
    }
  }

  .binds-textarea {
    min-height: 32px;
    max-height: 230px;
    padding: 5px 0;
    resize: none;
    line-height: 1.3em;
  }

  .binds-helper-text,
  .binds-error,
  .binds-count {
    height: 20px;
    position: absolute;
    bottom: -22px;
    font-size: 12px;
    transition: 0.3s $binds-transition-default-timing;
  }

  .binds-error {
    display: block !important;
    left: 0;
    opacity: 0;
    transform: translate3d(0, -8px, 0);
  }

  .binds-count {
    right: 0;
  }

  .binds-input-action {
    width: 32px;
    min-width: 32px;
    height: 32px;
    margin: 0;
    position: absolute;
    top: 16px;
    right: 0;
    transition: $binds-transition-default;

    &.binds-input-action-enter-active,
    &.binds-input-action-leave-active {
      opacity: 0;
    }

    &.binds-input-action-enter-to {
      opacity: 1;
    }
  }

  > .binds-icon {
    margin: 4px auto;
    position: relative;
    z-index: 3;
    transition: $binds-transition-stand;

    &:last-of-type:not(:first-child):after {
      display: none;
    }

    &:after {
      width: 37px;
      height: 4px;
      position: absolute;
      left: -1px;
      bottom: -5px;
      transition: 0.3s $binds-transition-default-timing;
      content: "";
    }

    ~ {
      label {
        left: 36px;
      }

      .binds-input,
      .binds-textarea,
      .binds-file {
        margin-left: 12px;
      }
    }
  }
}

.binds-field {
  + .binds-has-textarea:not(.binds-autogrow) {
    margin-top: 36px;
  }

  &.binds-has-placeholder {
    label {
      pointer-events: auto;
      top: 10px;
      opacity: 0;
      font-size: 12px;
    }

    .binds-input,
    .binds-textarea {
      font-size: 16px;
    }
  }

  &.binds-has-textarea:not(.binds-autogrow) {
    &:before,
    &:after {
      height: auto;
      pointer-events: none;
      top: 0;
      bottom: 0;
      transform: none;
      background: none !important;
      border: 1px solid transparent;
      border-radius: 3px;
    }

    &:before {
      border-width: 2px;
    }

    label {
      top: 16px;
      left: 16px;
    }

    .binds-textarea {
      min-height: 100px;
      padding: 0 16px;
      resize: vertical;
    }

    > .binds-icon {
      position: absolute;
      top: 6px;
      right: 6px;
      z-index: 3;
    }

    .binds-count {
      right: 6px;
      bottom: 2px;
    }

    .binds-clear {
      top: 6px;
      right: 6px;
    }

    &.binds-focused,
    &.binds-has-value {
      label {
        top: 6px;
      }

      .binds-textarea {
        padding-top: 10px;
      }
    }
  }

  &.binds-has-file {
    &:before,
    &:after,
    label {
      left: 36px;
    }

    .binds-input {
      margin-left: 12px;
    }
  }

  &.binds-focused,
  &.binds-highlight {
    &:before {
      opacity: 1;
      transform: scaleX(1);
      transition: 0.3s $binds-transition-default-timing;
      transition-property: border, opacity, transform;
    }
  }

  &.binds-focused,
  &.binds-has-value {
    label {
      pointer-events: auto;
      top: 0;
      opacity: 1;
      font-size: 12px;
    }

    .binds-input,
    .binds-textarea {
      font-size: 16px;
    }
  }

  &.binds-inline {
    label {
      pointer-events: none;
    }

    &.binds-focused {
      label {
        top: 23px;
        font-size: 16px;
      }
    }

    &.binds-has-value {
      label {
        opacity: 0;
      }
    }
  }

  &.binds-disabled {
    &:after {
      background: bottom left repeat-x;
      background-size: 4px 1px;
    }
  }

  &.binds-has-password {
    .binds-toggle-password {
      margin: 0;
      position: absolute;
      right: 0;
      bottom: -2px;

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  &.binds-clearable {
    .binds-input {
      padding-right: 30px;
    }
  }

  &.binds-invalid {
    @keyframes binds-invalid-shake {
      10%,
      90% {
        transform: translate3d(-1px, 0, 0);
      }

      30%,
      70% {
        transform: translate3d(-4px, 0, 0);
      }

      40%,
      60% {
        transform: translate3d(4px, 0, 0);
      }
    }

    &.binds-has-value label:not(:focus) {
      animation: binds-invalid-shake 0.4s $binds-transition-default-timing both;
      backface-visibility: hidden;
      perspective: 1000px;
    }

    &.binds-has-textarea:not(.binds-autogrow) {
      &:before {
        border-width: 2px;
      }
    }

    .binds-error {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    .binds-helper-text {
      opacity: 0;
      transform: translate3d(0, -8px, 0);
    }
  }

  &.binds-required {
    label:after {
      position: absolute;
      top: 2px;
      right: 0;
      transform: translateX(calc(100% + 2px));
      content: "*";
      line-height: 1em;
      vertical-align: top;
    }
  }
}
</style>
