<template>
  <div v-show="false" class="binds-drawer binds-right-previous" :class="drawerClasses"></div>
</template>

<script>
import BindsComponent from '../../core/BindsComponent'
import BindsPropValidator from '../../core/utils/BindsPropValidator'

export default new BindsComponent({
  name: 'BindsDrawer',
  props: {
    bindsPermanent: {
      type: String,
      ...BindsPropValidator('binds-permanent', ['full', 'clipped', 'card'])
    },
    bindsPersistent: {
      type: String,
      ...BindsPropValidator('binds-persistent', ['mini', 'full'])
    },
    bindsActive: Boolean,
    bindsFixed: Boolean
  },
  computed: {
    drawerClasses () {
      let classes = {
        'binds-temporary': this.isTemporary,
        'binds-persistent': this.bindsPersistent,
        'binds-permanent': this.bindsPermanent,
        'binds-active': this.bindsActive,
        'binds-fixed': this.bindsFixed
      }

      if (this.bindsPermanent) {
        classes['binds-permanent-' + this.bindsPermanent] = true
      }

      if (this.bindsPersistent) {
        classes['binds-persistent-' + this.bindsPersistent] = true
      }

      return classes
    },
    isTemporary () {
      return !this.bindsPermanent && !this.bindsPersistent
    }
  }
})
</script>
