<template>
  <binds-icon class="binds-icon-image" v-once>
    <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 10l5 5 5-5z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  </binds-icon>
</template>

<script>
import BindsIcon from '../../components/BindsIcon/BindsIcon'

export default {
  name: 'BindsDropDownIcon',
  components: {
    BindsIcon
  }
}
</script>
