export default {
  max_length: 'Maximum characters: ',
  multiple_and: 'and',
  other_required: 'Fill the field',
  multiple_answer_required: 'You must select between',
  multiple_options: 'options',
  answers: {
    yes: 'Yes',
    no: 'No',
    maybe: 'Maybe',
    dont_know: 'Don\'t know',
    excellent: 'Excellent',
    very_good: 'Very good',
    satifying: 'Satisfying',
    bad: 'Bad',
    very_bad: 'Very bad',
    very_satisfied: 'Very satisfied',
    satisfied: 'Satisfied',
    indifferent: 'Indifferent',
    unsatisfied: 'Unsatisfied',
    very_unsatisfied: 'Very unsatisfied',
    ces: {
      totally_disagree: 'I Totally disagree',
      disagree: 'I Disagre',
      partially_disagree: 'Partially Disagree',
      neutral: 'Neutral',
      partially_agree: 'Partially agree',
      agree: 'I agree',
      totally_agree: 'I Totally agree'
    },
    nvs: {
      nvs_op1: 'Significantly better',
      nvs_op2: 'A little better',
      nvs_op3: 'Neither better nor worse',
      nvs_op4: 'A little worse',
      nvs_op5: 'Significantly worse'
    },
    referral: {
      alert_email: 'Invalid email'
    }
  }
}
