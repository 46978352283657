import Vue from 'vue'
import App from './App.vue'
import Widget from './components/Widget.vue'
import router from './router'
import store from './store/index'
import VueHead from 'vue-head'
import './assets/css/main.scss'
import DesignSystem from '../node_modules/@binds-tech/binds-design-system/src/system'
import i18n from './translations/i18n'

// (optional) 'Custom elements polyfill'
import '@ungap/custom-elements'
import vueCustomElement from 'vue-custom-element'
Vue.use(vueCustomElement)
Vue.use(VueHead)

DesignSystem.install(Vue)

Vue.config.productionTip = false

// use vue-custom-element
App.store = store
App.router = router
App.i18n = i18n

Widget.store = store
Widget.router = router
Widget.i18n = i18n

Vue.customElement('vue-widget', Widget)
Vue.customElement('vue-widget-debug', App)
