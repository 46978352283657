<template>
  <div id="bindsApp">
    <!-- <br>Rota para debugar tudo, os parâmetros são enviados pelo arquivo App.vue<br> -->
    <!--
      <div><link href="https://widget-v3.web.app/css/app.css" rel=stylesheet><div><vue-widget widget_position="right" widget_title="feedback" buttons="#ff607d" width="62" height="" text_buttons="#ffffff" background="#fff" texts="#535151" timeout="0" width_metric="px" survey_id="610a9ef27605d727383ed341" close_button="true" from='{"name": "", "email": "", "phone":""}' is_to_post="true" metadata='{}'/></div><script src="https://widget-v3.web.app/js/app.js"> </script></div>
    -->
    <Widget v-bind="{ ...$props, ...$attrs }" />
  </div>
</template>
<script>
import Widget from './components/Widget'

export default {
  components: {
    Widget
  },
  props: [
    'close_permanently',
    'action_config',
    'widget_position',
    'close_button',
    'height',
    'width',
    'background',
    'buttons',
    'texts',
    'timeout',
    'width_metric',
    'height_metric',
    'survey_id',
    'float_button',
    'metadata',
    'text_buttons',
    'widget_title',
    'send_response',
    'is_to_post',
    'is_preview',
    'from',
    'hidde_after_answer',
    'hidde_when_click_to_close',
    'response_background',
    'font_family',
    'font_size',
    'response_font_color',
    'text_props',
    'border_radius',
    'id',
    'font_family_dynamic'
  ]
}
</script>

<style lang="scss">
body{
  background-color: transparent;
}
@font-face {
  font-family: 'bicons';
  src: url('../node_modules/@binds-tech/binds-design-system/src/assets/fonts/bicons.eot');
  src: url('../node_modules/@binds-tech/binds-design-system/src/assets/fonts/bicons.svg') format('svg'),
    url('../node_modules/@binds-tech/binds-design-system/src/assets/fonts/bicons.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
</style>
