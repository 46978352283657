import moment from 'moment'

export const setEvent = {
  dispatchMyEvent,
  sendResponseEvent
}

function dispatchMyEvent (payload, widgetId) {
  if (!widgetId) return

  const customTag = document.getElementById(widgetId)

  const eventCustom = new CustomEvent('binds-survey-interaction', { detail: payload })

  customTag.dispatchEvent(eventCustom)
}

function sendResponseEvent (payload, widgetId, questionId, isDone) {
  const body = {
    questionId,
    sendingId: payload._id,
    createdAt: payload.createdAt,
    respondedAt: moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    doneAt: isDone ? moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
    type: 'response'
  }

  dispatchMyEvent(body, widgetId)
}
