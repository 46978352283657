<template>
  <div class="binds-steppers" :class="[steppersClasses, $bindsActiveTheme]">
    <div class="binds-steppers-navigation" v-if="!bindsVertical">
      <binds-step-header v-for="(_, index) in BindsSteppers.items" :key="index" :index="index" />
    </div>

    <div class="binds-steppers-wrapper" :style="contentStyles">
      <div class="binds-steppers-container" :style="containerStyles">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import BindsComponent from '../../core/BindsComponent'
import BindsObserveElement from '../../core/utils/BindsObserveElement'
import BindsThrottling from '../../core/utils/BindsThrottling'
import BindsStepHeader from './BindsStepHeader'

export default new BindsComponent({
  name: 'BindsSteppers',
  components: {
    BindsStepHeader
  },
  props: {
    bindsSyncRoute: Boolean,
    bindsDynamicHeight: Boolean,
    bindsVertical: Boolean,
    bindsLinear: Boolean,
    bindsAlternative: Boolean,
    bindsActiveStep: [String, Number]
  },
  data () {
    return {
      activeStepIndex: 0,
      noTransition: true,
      contentStyles: {},
      activeButtonEl: null,
      BindsSteppers: {
        activeStep: 0,
        isLinear: false,
        isVertical: false,
        items: {},
        syncRoute: this.bindsSyncRoute,
        getStepperNumber: this.getStepperNumber,
        setActiveStep: this.setActiveStep,
        isPreviousStepperDone: this.isPreviousStepperDone
      }
    }
  },
  provide () {
    return { BindsSteppers: this.BindsSteppers }
  },
  computed: {
    steppersClasses () {
      return {
        'binds-no-transition': this.noTransition,
        'binds-alternative': this.bindsAlternative,
        'binds-horizontal': !this.bindsVertical,
        'binds-vertical': this.bindsVertical,
        'binds-dynamic-height': this.bindsDynamicHeight
      }
    },
    activeIndex () {
      return this.BindsSteppers.activeStep
    },
    containerStyles () {
      return {
        transform: !this.bindsVertical && `translate3D(${-this.activeStepIndex * 100}%, 0, 0)`
      }
    }
  },
  watch: {
    bindsActiveStep (stepper) {
      this.BindsSteppers.activeStep = stepper
      this.$emit('binds-changed', stepper)
    },
    bindsLinear (isLinear) {
      this.BindsSteppers.isLinear = isLinear
    },
    bindsVertical (isVertical) {
      this.BindsSteppers.isVertical = isVertical
    },
    activeIndex () {
      this.$nextTick(this.setActiveButtonEl)
    },
    activeStepIndex () {
      this.onActiveStepIndex()
      this.$nextTick(this.calculateStepperPos)
    },
    activeButtonEl (activeButton) {
      this.activeStepIndex = activeButton ? [].indexOf.call(activeButton.parentNode.childNodes, activeButton) : 0
    },
    '$route' () {
      this.$nextTick(this.setActiveButtonEl)
    }
  },
  methods: {
    hasActiveStep () {
      return this.BindsSteppers.activeStep || this.bindsActiveStep
    },
    getItemsAndKeys () {
      const items = this.BindsSteppers.items

      return {
        items,
        keys: Object.keys(items)
      }
    },
    getStepperNumber (id) {
      const stepperNames = Object.keys(this.BindsSteppers.items)

      return stepperNames.indexOf(id) + 1
    },
    isStepperDone (id) {
      return this.BindsSteppers.items[id].done
    },
    isPreviousStepperDone (id) {
      const { items } = this.BindsSteppers
      const stepperNames = Object.keys(items)
      const activeIndex = this.getStepperNumber(id) - 2
      const previousIndex = stepperNames[activeIndex]

      if (!previousIndex) {
        return true
      }

      return items[previousIndex].done
    },
    isStepperEditable (id) {
      return this.BindsSteppers.items[id].editable
    },
    setStepperAsDone (id) {
      this.BindsSteppers.items[id].done = true
    },
    setPreviousStepperAsDone (newId) {
      const activeIndex = this.getStepperNumber(this.BindsSteppers.activeStep)
      const newIndex = this.getStepperNumber(newId)

      if (newIndex > activeIndex) {
        this.setStepperAsDone(this.BindsSteppers.activeStep)
      }
    },
    setActiveStep (id) {
      if (this.bindsLinear && !this.isPreviousStepperDone(id)) {
        return false
      }

      if (id !== this.BindsSteppers.activeStep && (this.isStepperEditable(id) || !this.isStepperDone(id))) {
        this.setPreviousStepperAsDone(id)
        this.BindsSteppers.activeStep = id
        this.$emit('binds-changed', id)
        this.$emit('update:bindsActiveStep', id)
        this.BindsSteppers.items[id].error = null
      }
    },
    setActiveButtonEl () {
      this.activeButtonEl = this.$el.querySelector('.binds-stepper-header.binds-button.binds-active')
    },
    setActiveStepByIndex (index) {
      const { keys } = this.getItemsAndKeys()

      if (!this.hasActiveStep()) {
        this.BindsSteppers.activeStep = keys[index]
      }
    },
    setupObservers () {
      const steppersContent = this.$el.querySelector('.binds-steppers-wrapper')

      if ('ResizeObserver' in window) {
        this.resizeObserver = new window.ResizeObserver(this.calculateStepperPos)
        this.resizeObserver.observe(this.$el)
      } else {
        window.addEventListener('resize', this.calculateStepperPos)
      }

      if (steppersContent) {
        this.resizeObserver = BindsObserveElement(this.$el.querySelector('.binds-steppers-wrapper'), {
          childList: true,
          characterData: true,
          subtree: true
        }, this.calculateStepperPos)
      }
    },
    calculateStepperPos () {
      if (!this.bindsVertical) {
        const stepperElement = this.$el.querySelector(`.binds-stepper:nth-child(${this.activeStepIndex + 1})`)

        this.contentStyles = {
          height: `${stepperElement.offsetHeight}px`
        }
      }
    },
    onActiveStepIndex () {
      const { items, keys } = this.getItemsAndKeys()
      if (!this.hasActiveStep() && !this.activeStepIndex) {
        this.BindsSteppers.activeStep = keys[0]
      } else {
        this.BindsSteppers.activeStep = keys[this.activeStepIndex]

        for (let i = 0; i < this.activeStepIndex; i++) {
          this.setStepperAsDone(keys[i])
        }
      }
    }
  },
  created () {
    this.calculateStepperPos = BindsThrottling(this.calculateStepperPos, 300)
    this.BindsSteppers.activeStep = this.bindsActiveStep
    this.BindsSteppers.isLinear = this.bindsLinear
    this.BindsSteppers.isVertical = this.bindsVertical
  },
  mounted () {
    this.$nextTick().then(() => {
      if (!this.bindsSyncRoute) {
        this.setActiveStepByIndex(0)
      } else {
        this.onActiveStepIndex()
      }

      return this.$nextTick()
    }).then(() => {
      this.setActiveButtonEl()
      this.calculateStepperPos()

      window.setTimeout(() => {
        this.noTransition = false
        this.setupObservers()
      }, 100)
    })
  },
  beforeDestroy () {
    if (!('ResizeObserver' in window)) {
      window.removeEventListener('resize', this.calculateStepperPos)
    }
  }
})
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";
  @import "../BindsElevation/mixins";
  @import "../BindsLayout/mixins";

  $binds-stepper-icon-size: 24px;

  .binds-steppers {
    transition: .3s $binds-transition-default-timing;
    transition-property: color, background-color;
    will-change: color, background-color;

    &.binds-no-transition * {
      transition: none !important;
    }

    &.binds-dynamic-height .binds-steppers-wrapper {
      transition: height .3s $binds-transition-default-timing;
      will-change: height;
    }

    &.binds-horizontal.binds-alternative {
      .binds-stepper-header {
        height: 104px;

        &:first-of-type {
          .binds-stepper-icon,
          .binds-stepper-number {
            &:before {
              content: none;
            }
          }
        }

        &:last-of-type {
          .binds-stepper-icon,
          .binds-stepper-number {
            &:after {
              content: none;
            }
          }
        }

        .binds-ripple {
          justify-content: center;
        }

        .binds-button-content {
          padding-top: 16px;
          flex-direction: column;

          &:before,
          &:after {
            content: none;
          }
        }

        .binds-stepper-text {
          height: 32px;
          justify-content: flex-start;
          text-align: center;
        }

        .binds-stepper-icon,
        .binds-stepper-number {
          margin: 0 8px 8px;
          position: relative;

          &:after,
          &:before {
            width: 9999%;
            height: 1px;
            position: absolute;
            top: 50%;
            z-index: 2;
            transition: background-color .3s $binds-transition-default-timing;
            will-change: background-color;
            content: " ";
          }

          &:after {
            left: calc(100% + 8px);
          }

          &:before {
            right: 32px;
          }
        }
      }
    }

    &.binds-vertical {
      .binds-stepper-header {
        height: 56px;

        .binds-ripple {
          padding: 0 24px 0 16px;
        }
      }

      .binds-steppers-container {
        display: block;
      }

      .binds-button-content {
        &:before,
        &:after {
          content: none;
        }
      }

      .binds-stepper-icon,
      .binds-stepper-number {
        margin-right: 12px;
      }

      .binds-stepper {
        flex: none;
        padding: 0;
        position: relative;

        &:last-of-type:after {
          content: none;
        }

        &:after {
          width: 1px;
          position: absolute;
          top: 48px;
          bottom: -8px;
          left: 36px;
          z-index: 2;
          transition: background-color .3s $binds-transition-default-timing;
          will-change: background-color;
          content: " ";
        }
      }
    }
  }

  .binds-steppers-navigation {
    @include binds-elevation(2);
    display: flex;

    .binds-stepper-header {
      width: auto;
    }
  }

  .binds-stepper-header {
    width: 100%;
    height: 72px;
    margin: 0;
    flex: 1;
    border-radius: 0;
    font-weight: 400;
    text-align: left;
    text-transform: none;

    &:first-of-type .binds-button-content:before {
      content: none;
    }

    &:last-of-type .binds-button-content:after {
      content: none;
    }

    &.binds-active,
    &.binds-error {
      font-weight: 500;
    }

    .binds-ripple {
      padding: 0 16px;
      justify-content: flex-start;
    }

    .binds-button-content {
      padding: 0 8px;
      display: flex;
      align-items: center;
      transition: color .3s $binds-transition-default-timing;
      will-change: color;

      &:after,
      &:before {
        height: 1px;
        position: absolute;
        top: 50%;
        transition: background-color .3s $binds-transition-default-timing;
        will-change: background-color;
        content: " ";
      }

      &:after {
        width: 9999%;
        left: 100%;
      }

      &:before {
        width: 16px;
        left: -16px;
      }

      svg {
        transition: .3s $binds-transition-default-timing;
        transition-property: color, fill;
        will-change: color, fill;
      }
    }
  }

  .binds-stepper-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 16px;
    white-space: nowrap;
  }

  .binds-stepper-icon,
  .binds-stepper-number {
    margin-right: 8px;
    transition: color .3s $binds-transition-default-timing;
    will-change: color;
  }

  .binds-stepper-number {
    width: $binds-stepper-icon-size;
    height: $binds-stepper-icon-size;
    border-radius: $binds-stepper-icon-size;
    transition: .3s $binds-transition-default-timing;
    transition-property: color, background-color;
    will-change: color, background-color;
    font-size: 12px;
    line-height: $binds-stepper-icon-size;
    text-align: center;
  }

  .binds-stepper-done {
    width: 20px;
    height: 20px;
    transform: translateY(-1px);
  }

  .binds-stepper-editable {
    width: 14px;
    height: 14px;
    transform: translateY(-1px);
  }

  .binds-stepper-error,
  .binds-stepper-description {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .binds-stepper-description {
    opacity: .54;
  }

  .binds-steppers-wrapper {
    overflow: hidden;
    transition: none;
    will-change: height;
  }

  .binds-steppers-container {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    transform: translateZ(0);
    transition: transform .35s $binds-transition-default-timing;
    will-change: transform;
  }

  .binds-stepper {
    width: 100%;
    flex: 1 0 100%;
    padding: 16px 24px;

    @include binds-layout-small {
      padding: 8px 16px;
    }
  }
</style>
