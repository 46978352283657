<template>
  <binds-menu
    class="binds-select"
    :class="{ 'binds-disabled': disabled }"
    :binds-close-on-select="false"
    :binds-active.sync="showSelect"
    :binds-offset-x="offset.x"
    :binds-offset-y="offset.y"
    :binds-dense="bindsDense"
    @binds-closed="onClose"
  >
    <binds-input
      class="binds-input binds-select-value"
      v-model="BindsSelect.label"
      ref="input"
      readonly
      :disabled="disabled"
      :required="required"
      :placeholder="placeholder"
      v-on="inputListeners"
      v-bind="attrs"
      @focus.prevent="onFocus"
      @blur.prevent="removeHighlight"
      @click="openSelect"
      @keydown.down="openSelect"
      @keydown.enter="openSelect"
      @keydown.space="openSelect"
    />
    <binds-drop-down-icon @click.native="openSelect" />

    <keep-alive>
      <binds-menu-content
        ref="menu"
        class="binds-select-menu"
        :binds-content-class="bindsClass"
        :style="menuStyles"
        @enter="onMenuEnter"
      >
        <slot v-if="showSelect" />
      </binds-menu-content>
    </keep-alive>

    <div v-if="!showSelect" v-show="false">
      <slot />
    </div>

    <input class="binds-input-fake" v-model="model" :disabled="disabled" readonly tabindex="-1" />
    <select readonly v-model="model" v-bind="attributes" tabindex="-1"></select>
  </binds-menu>
</template>

<script>
import raf from 'raf'
import BindsComponent from '../../../core/BindsComponent'
import BindsDropDownIcon from '../../../core/icons/BindsDropDownIcon'
import BindsMenu from '../../BindsMenu/BindsMenu'
import BindsMenuContent from '../../BindsMenu/BindsMenuContent'
import BindsInput from '../BindsInput/BindsInput'
import BindsFieldMixin from '../BindsFieldMixin'

const defaultOffset = {
  x: -15,
  y: -48
}

export default {
  name: 'BindsSelect',
  components: {
    BindsInput,
    BindsMenu,
    BindsMenuContent,
    BindsDropDownIcon
  },
  mixins: [BindsFieldMixin],
  props: {
    bindsDense: Boolean,
    bindsClass: String,
    multiple: Boolean,
    id: String,
    name: String
  },
  inject: ['BindsField'],
  data () {
    return {
      menuStyles: {},
      offset: {
        x: defaultOffset.x,
        y: 0
      },
      showSelect: true,
      didMount: false,
      BindsSelect: {
        items: {},
        label: null,
        multiple: false,
        modelValue: this.localValue,
        setValue: this.setValue,
        setContent: this.setContent,
        setMultipleValue: this.setMultipleValue,
        setMultipleContent: this.setMultipleContent
      }
    }
  },
  provide () {
    const BindsSelect = this.BindsSelect

    return { BindsSelect }
  },
  computed: {
    attrs () {
      return {
        ...this.$attrs,
        name: this.name,
        id: undefined
      }
    },
    inputListeners () {
      return {
        ...this.$listeners,
        input: undefined
      }
    }
  },
  watch: {
    localValue: {
      immediate: true,
      handler (val) {
        this.setFieldContent()
        this.BindsSelect.modelValue = this.localValue

        if (this.didMount) {
          this.emitSelected(val)
        }
      }
    },
    multiple: {
      immediate: true,
      handler (isMultiple) {
        this.BindsSelect.multiple = isMultiple
        this.$nextTick(this.initialLocalValueByDefault)
      }
    }
  },
  methods: {
    elHasScroll (el) {
      return el.scrollHeight > el.offsetHeight
    },
    scrollToSelectedOption (el, menu) {
      const top = el.offsetTop
      const elHeight = el.offsetHeight
      const menuHeight = menu.offsetHeight

      menu.scrollTop = top - (menuHeight - elHeight) / 2
    },
    setOffsets (target) {
      if (!this.$isServer) {
        const menu = this.$refs.menu.$refs.container

        if (menu) {
          const selected = target || menu.querySelector('.binds-selected')

          if (selected) {
            this.scrollToSelectedOption(selected, menu)
            this.offset.y =
              defaultOffset.y - selected.offsetTop + menu.scrollTop + 8
            this.menuStyles = {
              'transform-origin': `0 ${Math.abs(this.offset.y)}px`
            }
          } else {
            this.offset.y = defaultOffset.y + 1
            this.menuStyles = {}
          }
        }
      }
    },
    onMenuEnter () {
      if (!this.didMount) {
        return
      }

      this.setOffsets()
      this.BindsField.focused = true
      this.$emit('binds-opened')
    },
    applyHighlight () {
      this.BindsField.focused = false
      this.BindsField.highlighted = true
      this.$refs.input.$el.focus()
    },
    onClose () {
      this.$emit('binds-closed')
      if (this.didMount) {
        this.applyHighlight()
      }
    },
    onFocus () {
      if (this.didMount) {
        this.applyHighlight()
      }
    },
    removeHighlight () {
      this.BindsField.highlighted = false
    },
    openSelect () {
      if (!this.disabled) {
        this.showSelect = true
      }
    },
    arrayAccessorRemove (arr, index) {
      let before = arr.slice(0, index)
      let after = arr.slice(index + 1, arr.length)
      return before.concat(after)
    },
    toggleArrayValue (value) {
      let index = this.localValue.indexOf(value)
      let includes = index > -1
      if (!includes) {
        this.localValue = this.localValue.concat([value])
      } else {
        this.localValue = this.arrayAccessorRemove(this.localValue, index)
      }
    },
    setValue (newValue) {
      this.model = newValue
      this.setFieldValue()
      this.showSelect = false
    },
    setContent (newLabel) {
      this.BindsSelect.label = newLabel
    },
    setContentByValue () {
      const textContent = this.BindsSelect.items[this.localValue]

      if (textContent) {
        this.setContent(textContent)
      } else {
        this.setContent('')
      }
    },
    setMultipleValue (value) {
      const newValue = value
      this.toggleArrayValue(newValue)
      this.setFieldValue()
    },
    setMultipleContentByValue () {
      if (!this.localValue) {
        this.initialLocalValueByDefault()
      }

      let content = []

      this.localValue.forEach(item => {
        const textContent = this.BindsSelect.items[item]

        if (textContent) {
          content.push(textContent)
        }
      })

      this.setContent(content.join(', '))
    },
    setFieldContent () {
      if (this.multiple) {
        this.setMultipleContentByValue()
      } else {
        this.setContentByValue()
      }
    },
    isLocalValueSet () {
      return this.localValue !== undefined && this.localValue !== null
    },
    setLocalValueIfMultiple () {
      if (this.isLocalValueSet()) {
        this.localValue = [this.localValue]
      } else {
        this.localValue = []
      }
    },
    setLocalValueIfNotMultiple () {
      if (this.localValue.length > 0) {
        this.localValue = this.localValue[0]
      } else {
        this.localValue = null
      }
    },
    initialLocalValueByDefault () {
      let isArray = Array.isArray(this.localValue)

      if (this.multiple && !isArray) {
        this.setLocalValueIfMultiple()
      } else if (!this.multiple && isArray) {
        this.setLocalValueIfNotMultiple()
      }
    },
    emitSelected (value) {
      this.$emit('binds-selected', value)
    }
  },
  mounted () {
    this.showSelect = false
    this.setFieldContent()

    this.$nextTick().then(() => {
      this.didMount = true
    })
  },
  updated () {
    this.setFieldContent()
  }
}
</script>

<style lang="scss">
@import "../../BindsAnimation/variables";

.binds-menu.binds-select {
  display: flex;
  flex: 1;
  overflow: auto;

  &:not(.binds-disabled) {
    .binds-input,
    .binds-icon {
      cursor: pointer;
      outline: none;
    }
  }

  .binds-input {
    flex: 1;
    min-width: 0;
  }

  select,
  .binds-input-fake {
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
    clip: rect(0 0 0 0);
    border: 0;
  }
}
.binds-menu-content.binds-select-menu {
  z-index: 111;
  width: 100%;

  &.binds-menu-content-enter {
    transform: translate3d(0, -8px, 0) scaleY(0.3);
  }

  .binds-list {
    transition: opacity 0.3s $binds-transition-drop-timing;
  }
}
</style>
